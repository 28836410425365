let appName = process.env.VUE_APP_APP_NAME;
let appNameWithoutS = appName.substr(0, appName.length - 1);

let prefixOptions = [];
['Candid_', ' Clip Art ', ' Backgrounds ', ' Graphics '].forEach(postfix => {
	let prefix = appName + postfix;
	let prefixWithoutS = appNameWithoutS + postfix;
	prefixOptions.push(prefix, prefixWithoutS);
});

let prefixes = [...prefixOptions, appName, appNameWithoutS];

export default function fixAlbumName(album) {
	prefixes.forEach(function(prefix) {
		if(album.name.indexOf(prefix) === 0 && album.name != prefix) {
			album.name = album.name.substr(prefix.length);
		}
	});
}