$.getChangelog = function() {
	var changelog = [];

	if($.plicLicenseFeatures['composites-enabled']) {
		$.merge(changelog, [
			{
				date: '12/02/2024',
				title: 'Composites Setting: "Add Overflow Line"',
				content: 'On Composites there is a new setting in the Subject Settings dialog: "Add Overflow Line".' +
					'When turned on, the last line will overflow a long line to the next line before trying to resize. ' +
					'This will be useful for cases such as long titles or last names. '
			},
			{
				date: '08/26/2024',
				title: 'Sheets: Reverse Sort and Reverse Column Sort',
				content: 'Two new sort options have been added to Sheets: Reverse and Reverse Column sorting. '
			},
			{
				date: '08/07/2024',
				title: 'Creating layouts from group row Proofs',
				content: 'After you have collected row information from a proof created with the option "Uploaded group photos with rows", you can now render layouts from them. ' +
					'On a layout there is a new subject pose called <i>Proof Group Photo</i>. ' +
					'After selecting that pose, the layout will switch to be displaying data from the proof. ' +
					'You can pull the group name with the dynamic variable <i>%proof group name%</i>. ' +
					'You can pull the row information with the dynamic variables <i>%proof row names1%</i>, <i>%proof row names2%</i>, etc... and <i>%proof not pictured names%</i>. ' +
					'In addition, you can pull the custom fields you gather with the dynamic variables <i>%proof {field}%</i> (ie: if the field is Grade it would be <i>%proof grade%</i>).'
			},
			{
				date: '01/29/2024',
				title: 'Sheets: New option to render each subject pose',
				content: 'There is a new option when creating sheets: "Render each subject once for each pose". ' +
					'When checking the box for "Render each subject once for each pose", each subject will render one layout cell for each of it\'s poses. ' +
					'You can use the option "Maximum number of poses for each subject" to cap how many poses are rendered for each subject. ' +
					'If you want to have one subject per sheet page, you can also use the new option to Group By "Subject" and then each subject will be on their own page. '
			},
			{
				date: '09/21/2023',
				title: 'Reports: Filter by package sku or batch # in Cross Project reports',
				content: 'Cross Project reports load all orders in a date range and then allow you to do client side filtering on the resulting data. ' +
					'If you want to generate a report for only specific skus or batches, you would need to wait for all of the orders to load first. ' +
					'You can now filter based on package sku or batch # before all of the data is loaded, dramatically speeding up how long these reports take to generate. ' +
					'You can find these new options under the Special Filters section of Cross Project reports. '
			},
			{
				date: '08/30/2023',
				title: 'Reports: New report for Cross Project Package Summary',
				content: 'You can now run a cross project project report to get a package summary. ' +
					'It shows package and product totals similar to the existing "Job Order Summary"/"Project Package Summary" report. ' +
					'Like the other cross project reports, this report will include all orders in the given date range. ' +
					'The "Job Order Summary" report has been renamed to "Project Package Summary" for clarity and consistency. '
			},
			{
				date: '04/17/2023',
				title: 'Proofs: Custom email subject line',
				content: 'When sending out proof emails, you can now customize the subject line of the email. '
			},
			{
				date: '10/17/2022',
				title: 'Custom project templates',
				content: 'Under Studio/Lab Settings, there is a new button along the top called "Project Templates". ' +
					'From here you can configure which fields are editable under Subject Management based on project type. ' +
					'By default one template is created for Composites. ' +
					'You can set different fields to be editable for different types of projects. ' +
					'You can create new project types by typing in the Project Types box when editing a Project Template. ' +
					'You can also create new fields for each project type so you do not have to create them each time you are uploading subjects into the system. '
			},
			{
				date: '09/15/2022',
				title: 'Reports: New report for a summary of submitted orders for a project',
				content: 'There is a new report called "Submitted Project Package Summary" which summarizes the packages purchased from submitted orders. ' +
					'It defaults to show all submitted orders, but you can also filter on the date the order submitted. ' +
					'In addition, a date filter for when the order was created has been added for the existing "Project Package Summary" report. ' +
					'Batch # and Order Submitted Date have been added as available fields for the regular subject order reports. '
			},
			{
				date: '09/06/2022',
				title: 'Proofs: Senior pose selection',
				content: 'There is a new type of proofs for senior pose selection. ' +
					'Each subject with more than one photo to choose from will be in its own batch. ' +
					'From there, customers will be able to select which pose should be used. ' +
					'You will then be able to export the pose selection as a CSV or apply the selected pose to be the primary or yearbook pose for the current project. ' +
					'All proof types have been changed to show the proofs waiting for review from the proof page ahead of the ones which are already done. ' +
					'You can also now set a default proof email message template for each different proof type. ' +
					'<p/><img src="css/images/changelog/senior-pose-proofs.png" style="width: 100%"/>'
			},
			{
				date: '08/15/2022',
				title: 'Proofs: Deadlines',
				content: 'You can now specify a deadline from the bottom right of the Proofs tab. ' +
					'The deadline will show while they are working on their proof. ' +
					'After the deadline is past, they will no longer be able to make any additional comments/changes. ' +
					'Users who you have sent emails to from the Proofs tab will receive deadline reminders 1 week ahead of time and the day it is due. ' +
					'When sending emails from the Proofs tab, you can set users to not receive reminder emails. '
			},
			{
				date: '08/09/2022',
				title: 'Render layouts as new subject poses',
				content: 'When rendering a layout, there is now a checkbox at the bottom to upload that render as a subject pose. ' +
					'For Blueprint enabled projects, there will also be an option to stop those new poses from syncing to a Blueprint gallery. ' +
					'They will sync by default. ' +
					'<p/><img src="css/images/changelog/render-subject-poses.png" style="width: 100%"/>'
			},
			{
				date: '06/30/2022',
				title: 'Proofs: Email comments',
				content: 'Email addresses are now gathered from customers when proofing. ' +
					'You can see the email for each comment from the Proofs tab so you know who to ask for more information. '
			},
			{
				date: '06/23/2022',
				title: 'Proofs: New email setting for group row submission',
				content: 'The setting for receiving proof submissions is now broken out into composites vs group rows. ' +
					'You can have composite proofs and group row proofs go to two separate email addresses now.'
			},
			{
				date: '06/20/2022',
				title: 'Reporting for proofs',
				content: 'There are two reports now available for proofs from the Reports tab. ' +
					'One of the new reports is for proofs which are still in progress. ' +
					'This will only show batches that have not been approved or denied yet. ' +
					'The second new report is for all active proof batches which are denied. ' +
					'You can also configure your own custom report that filters on activity, whether the proof has been viewed, etc... ' +
					'Proof reports are filtered to the current seasons projects. ' +
					'<p/><img src="css/images/changelog/proof-reports.png" style="width: 100%"/>'
			},
			{
				date: '04/19/2022',
				title: 'Proofs: Added ability to gather extra data for group row approvals',
				content: 'Now you can gather teacher, grade, or any other data you want with group proofs. ' +
					'Along the bottom bar of the proofs tab is a new list button. ' +
					'After clicking on it, you can set a list of fields you want to gather data for. ' +
					'They can be any text labels that you want. ' +
					'Now when customers go to proof each batch, they will be able to enter that data into their proof for you to later use. ' +
					'<p/><img src="css/images/changelog/proof-extra-fields.png" style="height: 40em"/>'
			},
			{
				date: '12/13/2021',
				title: 'Proofs: New group row approval mode',
				content: 'When creating a proof for a job, there is a new option: "Uploaded group photos with rows". ' + 
					'Instead of entering standard comments, schools will be able to enter which students are on which rows of a group photo. ' +
					'They will also have a special row to enter students which are part of that class or group but are not in the picture. ' +
					'<p/><img src="css/images/changelog/proof-group-rows.png" style="width: 100%;"/>'
			},
			{
				date: '12/06/2021',
				title: 'Proofs: Upload any images',
				content: 'When creating a proof for a job, there are now two options: "Composite renders" and "Uploaded images with standard comments"". ' + 
					'Composite Renders create a proof from composite renders like you have always done. ' +
					'The second option now will allow you to upload any generic images you want and to proof them. ' +
					'A batch is created for every image you upload. ' +
					'The rest of the process is the same as it was for composite proofs. '
			},
			{
				date: '10/26/2021',
				title: 'Controlling apply to all',
				content: 'When using Apply to All on composites, you can now control what is applied and to which pages. ' +
					'The default is still to apply all content to all pages.  You can un-select content or pages you do not want applied. ' +
					'<p/><img src="css/images/changelog/apply-to-all-selection.png" style="width: 100%;"/>'
			},
			{
				date: '09/27/2021',
				title: 'Bulk changing a project\'s PLIC app list',
				content: 'Under Project Settings you can control which PLIC apps are able to see this project. ' +
					'You can now set projects in bulk from the Advanced sub-menu on the Composites tab. ' + 
					'<p/><img src="css/images/changelog/bulk-edit-project-apps.jpg" style="width: 100%;"/>'
			},
			{
				date: '08/25/2021',
				title: 'Proof comment threads',
				content: 'You can now reply directly to a proof comment instead of just adding another comment. ' +
					'This can be useful for keeping conversations about different issues separate from each other. ' +
					'To start a new comment thread, click on the Reply button below that comment instead of adding a new comment in the box at the bottom. ' + 
					'<p/><img src="css/images/changelog/comment-threads.jpg" style="width: 100%;"/>'
			},
			{
				date: '08/20/2021',
				title: 'Spellcheck right click menu',
				content: 'Now when you right click on a misspelled word, you will now get a different menu with our spelling suggestions. ' +
					'You will also be able to select "Add to dictionary" which will add the word to your organization\'s dictionary. ' +
					'You can go into Studio/Lab Settings -> Custom Dictionary to manage the words in your organization\'s dictionary. '
			},
			{
				date: '08/17/2021',
				title: 'New logic for composites with almost empty bottom rows',
				content: 'There is a new project setting called "Move subjects to keep bottom row filled". ' +
					'You can access it from the gears icon and clicking on "Subject Settings". ' +
					'This option is enabled by default for all new projects and disabled by default for existing projects since it changes the way composites are flowed onto the page. ' +
					'This new option is to help keep the number of subjects on each row more even. ' +
					'With this option enabled, it will pull a subject from the previous rows to keep the bottom row with roughly as many subjects as the upper rows. ' +
					'In the example below, you can see that it pulled 2 subjects from row 2 and 3 to make the bottom row more full. ' + 
					'With this option turned off, that bottom row only had a single subject in it. ' +
					'The downside is that there is more space between cells.  This can be turned on/off at any time. ' +
					'<p/><img src="css/images/changelog/filled-bottom-row.png" style="height: 34em"/>'
			},
			{
				date: '08/12/2021',
				title: 'Hard rotating subject images',
				content: 'If you have subject images which are rotated the wrong direction, you can now fix them from Subject Management. ' +
					'When editing a subject in Subject Management, click on the crop button, and then rotate left or right to fix the way the image is rotated. ' +
					'You can also rotate images when you approve parent portrait uploads. ' +
					'<p/><img src="css/images/changelog/hard-rotate-subject-image.jpg" style="width: 100%"/><p/>'
			},
			{
				date: '07/27/2021',
				title: 'Disabling proofs',
				content: 'You can now disable proofs so your customers cannot access them anymore. ' +
					'You can toggle access on and off as often as you want. ' +
					'You can also delete comments from proofs now. ' +
					'<p/><img src="css/images/changelog/disable-proofs.jpg" style="width: 100%"/><p/>'
			},
			{
				date: '07/07/2021',
				title: 'Limited users roles: Data Editor and Designer',
				content: 'There are two new user roles you can assign to your users: Data Editor and Designer. ' +
					'The Data Editor role is for users who you want to be editing subject data and making data changes requested from proofs. ' +
					'The Designer role is for users who you want to only be uploading graphics, working on layouts, and working on composites without changing any subject data. '
			},
			{
				date: '06/21/2021',
				title: 'Composite crop selection',
				content: 'For PLIC Match customers who can create multiple crops, you can now select which crop to use for composites. ' +
					'The first or primary crop is still the default. ' +
					'If you select a named crop, the composite will be updated with the most common aspect ratio from that crop selection. '
			},
			{
				date: '05/26/2021',
				title: 'Upload composite renders as group images',
				content: 'When rendering composites by the Render Batch or Render Elements buttons, there is now a checkbox to "Upload rendered composite as a group image to subjects". ' +
					'When checked, composite renders will automatically be uploaded to subjects as group images. ' + 
					'When rendering basic composites, the render will be uploaded to every subject in the batch who is not marked to be in all batches. ' +
					'When rendering individualized composites, the render for each subject will only be uploaded to that subject which is highlighted. '
			},
			{
				date: '05/19/2021',
				title: 'Rendering individualized composites based on ordered packages/products',
				content: 'You can now render individualized composites based on the ordered packages/products. ' +
					'This feature pulls from and only works if you have your orders in PLIC. ' +
					'You can set a studio wide default set of package or products to look for, and override that setting on a per project basis. ' +
					'Under Studio/Lab Settings you will see an option called "Individualized Composite Order Values" which will contain the list of SKUs to match against. ' +
					'The option "Individualized Composite Order Field" controls whether we are looking in the Package SKU or the Product SKU field. ' +
					'After setting a SKU to look for, when you go to render a single batch the default checkboxes will be changed to only be subjects who have ordered that package or product. ' +
					'<p/><img src="css/images/changelog/render-ordered-individualized-batch.png" style="width: 100%"/><p/>' +
					'When going to render multiple batches at a time, you have a checkbox to control whether to render all subjects or only ones who have ordered the package or product you have set. ' +
					'<p/><img src="css/images/changelog/render-ordered-individualized-batches.png" style="width: 100%"/><p/>'
			},
			{
				date: '05/11/2021',
				title: 'Cross project reports',
				content: 'You can now create cross project reports from the Reports tab. ' +
					'Two new default reports have been added - "Cross Project Order Summary" and "Cross Project Order Details". ' +
					'When selecting a cross project report, you will be prompted to enter either how many past days of orders you want or an explicit date range. ' +
					'Cross project reports can take longer to load than a project report depending on how many projects you have in your organization. '
			},
			{
				date: '05/10/2021',
				title: 'Rotated large cell composites',
				content: 'You can now rotate large cells in a composite. ' +
					'It can be rotated by dragging the green dot above the selected image in the same way any other image or text boxes can be rotated. ' +
					'The subject\'s image will automatically shrink to fit in the space given. ' +
					'<p/><img src="css/images/changelog/rotated-large-cells.png" style="width: 100%"/><p/>'
			},
			{
				date: '03/23/2021',
				title: 'Reports',
				content: 'You can now access the Reports tab under Studio/Lab Settings. ' +
					'From here you can run reports on subject data and orders which are in PLIC. ' +
					'You will have some pre-built reports, but you can customize them for your needs by clicking on Report Settings. ' +
					'You can view more information on how to use reports from this <a class="ui primary tiny button" target="_blank" href="https://iqplsupport.com/en/articles/5047868-plic-elements-reporting">Article</a>' +
					'<p/><img src="css/images/changelog/reports.png" style="width: 100%"/><p/>'
			},
			{
				date: '02/12/2021',
				title: 'Proofs: Studio comments',
				content: 'You as a studio can now add comments to a proof from the Proofs tab. ' +
					'When you are viewing the proof, you will see the specific name of the user who made the comment. ' +
					'When your customers are viewing the proof, they will see the name as Studio Editor to help your schools know it was someone at the studio who did it. ' +
					'The add correction box has also been improved to always be visible instead of requiring you to scroll to the bottom of a list of old comments. '
			},
			{
				date: '02/09/2021',
				title: 'New large pose individualized composite layout',
				content: 'A new layout has been added which allows you to highlight an individualized subject however you want. ' +
					'The individualized subject is just an image frame which can be moved anywhere and be put at any size. ' +
					'You can even switch between using different poses or crops for the highlighted photo. ' +
					'You can setup the label textbox however you want as well with all dynamic fields such as %first name% and %last name% being linked to the individualized subject. ' +
					'Look for the "Large Pose Individualized Composite" layout near the bottom of the list right above the virtual group layouts. ' +
					'<p/><img src="css/images/changelog/large-pose-individualized-layout.jpg" style="width: 100%"/><p/>'
			},
			{
				date: '02/01/2021',
				title: 'Subject Management: Sort Descending',
				content: 'When doing a custom Filter By, you can now do descending sorts. ' +
					'Grade sorting has also been modified to treat K and PK specially (ex: PK, K, 01, 02, 03...)'
			},
			{
				date: '01/21/2021',
				title: 'Hide cell tool',
				content: 'You can now set specific cells in a composite layout to be hidden. ' +
					'<p/><img src="css/images/changelog/hide-cell-tool.png" style="width: 100%"/>'
			},
			{
				date: '01/11/2021',
				title: 'New subject uploader',
				content: 'When you go to upload subjects, you will be using a new uploader. ' +
					'This new uploader features a brand new subject mapping screen and other key enhancements. ' +
					'While mapping fields to columns in your data CSV, you can now see all rows in a table as well as the image which it is mapped to. ' +
					'If there are multiple images for a single subject, they are grouped together in the preview table. ' +
					'If there are subjects missing an image, they are listed in a warning before continuing to upload. ' +
					'Automatic mapping of fields has been improved to read from a PSPA CD\'s ReadMe.txt file as well as auto detecting header lines. ' +
					'You can also create new subject fields while mapping data before uploading. ' +
					'<p/><img src="css/images/changelog/new-subject-uploader.png" style="width: 100%"/><p/>'
			},
			{
				date: '10/14/2020',
				title: 'Proof email tracking',
				content: 'Any proof emails sent on or after 10/14/2020 will be tracked. ' +
					'When sending proof emails you can see a list of previously sent emails and whether they were opened. ' +
					'<p/><img src="css/images/changelog/proof-email-tracking.png" style="height: 40em"/>'
			},
			{
				date: '10/12/2020',
				title: 'New teacher only bottom row composite layout',
				content: 'There is a new composite layout to have a teacher only row on the bottom even when they are first in the batch. ' +
					'This can be used to save time in re-organizing batches to have the teachers be the last row instead of the first.'
			},
			{
				date: '10/06/2020',
				title: 'Editing subject names from Composer',
				content: 'When clicking on a subject cell, there is now a toolbar option to edit the subject\'s name directly from the Composer.'
			},
			{
				date: '09/30/2020',
				title: 'New Teacher Group composite layout',
				content: 'There is a new composite layout to render the teachers as a separate grid. ' +
					'The teachers can be moved around and resized to be any size, and the normal subjects will move out of their way. ' +
					'You can control which subjects are part of this secondary grid by going into Subject Management and marking them as Staff. ' +
					'<p/><img src="css/images/changelog/teacher-group-composites.jpg" style="height: 40em"/>'
			},
			{
				date: '09/08/2020',
				title: 'New wave composite layout',
				content: 'There is a new composite layout to make a wave from the subject cells. ' +
					'You can control how large the wave is as, well as how many subjects are flat before and after the wave. '
			},
			{
				date: '08/31/2020',
				title: 'Organization specific Google fonts',
				content: 'Under Studio/Lab Settings, you can now add Google fonts for just your own organization. ' +
					'These fonts will show up as choices on the Composer when desigining your composites or layouts.'
			},
			{
				date: '08/27/2020',
				title: 'Composites layer selection',
				content: 'You can now select content layers from the left sidebar. ' +
					'Subject cells do not show since they all have the same layer. ' +
					'<p/><img src="css/images/changelog/composites-layer-sidebar.png" style="height: 30em"/>'
			},
			{
				date: '08/24/2020',
				title: 'School logo on composites',
				content: 'If you have uploaded a school logo on PLIC, it is available under content to be used in your composites.'
			},
			{
				date: '08/19/2020',
				title: 'Composite snapshots',
				content: 'You can now create and rollback to snapshots of your composites. ' +
					'You can view and create snapshots under the top right menu button -> Project Management. ' +
					'By default a new snapshot is created every night if there have been any changes made that day. ' +
					'You can manually create new snapshots whenever you want. ' +
					'Only composite data and batches are saved in the snapshots.  Subject names/photos are not part of the snapshot and cannot be rolled back. '
			},
			{
				date: '08/10/2020',
				title: 'No padding composites',
				content: 'There is a new layout that has no names and no padding. ' +
					'Under Subject Settings, you can also specify the padding for any composite to be 0. ' +
					'There is a new option called "Distribute extra space to outside margins" in Subject Settings. ' +
					'When you turn it on, all of the extra space that is normally put between cells as extra padding is instead put outside the layout in the margins. ' +
					'Both this new option and 0 padding can be used with composites with names as well. ' +
					'<p/><img src="css/images/changelog/no-padding-composites.jpg" style="width:49%"/>' +
					'<img src="css/images/changelog/no-padding-composites2.jpg" style="width:49%"/>'
			},
			{
				date: '08/05/2020',
				title: 'Composite aspect ratio & margins',
				content: 'You can now manually edit the aspect ratio and margins for composites under Subject Settings.'
			},
			{
				date: '07/16/2020',
				title: 'Ordered backgrounds for green screen composites',
				content: 'When creating composites with green screen subject images, you can now control where the background is pulled from. ' +
					'There are three options: No background, use the project background, and use the ordered background. ' +
					'All composites default to using the project background. '  +
					'<p/><img src="css/images/changelog/ordered-background-composites.png" style="height: 30em"/>'
			},
			{
				date: '06/25/2020',
				title: 'Quick resorting batches',
				content: 'If anyone has moved subjects around to not be in their original order, there is now a quick button to resort the batch. ' +
					'In Subject Management, any time the order is not the same as the default order, a button titled "Resort subjects" will appear. ' +
					'Simply click it to get the subjects back in the right order.'
			},
			{
				date: '06/19/2020',
				title: 'Composite cell padding',
				content: 'From Subject Settings, you can now edit the base cell padding for composites. ' +
					'All composites default to 0.05" of padding, and padding is added to fill in the entire composite area. ' +
					'You can change the base padding to anything between 0.01" and 0.5" per cell'
			},
			{
				date: '05/11/2020',
				title: 'Render specific composites',
				content: 'After clicking on Preview Elements, there is a new button called Render Composites. ' +
					'After you click it, you are given the option to select which batches/composites you want to render. ' +
					'The composites will be downloadable as soon as they are done rendering instead of being emailed to you when complete. ' +
					'Using this option will also not submit the project. '
			},
			{
				date: '04/01/2020',
				title: 'New centering logic for composites',
				content: 'There is a new project setting called "Move subjects down to keep bottom row centered". ' +
					'You can access it from the gears icon and clicking on "Subject Settings". ' +
					'This option is enabled by default for all new projects and disabled by default for existing projects since it changes the way composites are flowed onto the page. ' +
					'This new option is for composites with content in the center of the last teacher only row. ' +
					'With this option enabled, it will pull a subject from the previous row to keep everything centered. ' +
					'<p/><img src="css/images/changelog/center-teacher-only-rows.jpg" style="width: 100%"/>'
			},
			{
				date: '01/29/2020',
				title: 'Subject label drop shadow/stroke',
				content: 'You can now add drop shadow and stroke to a subject\'s label on your composites'
			},
			{
				date: '01/17/2020',
				title: 'Toggle to allow downloading proof renders',
				content: 'On the Proofs tab, there is now a download button to the right of the zoom button. ' +
					'If you toggle it on, your customers will be able to download a pdf of the proof renders. ' +
					'This can be useful if you want to allow your customers to print out the proofs directly. ' +
					'It is off by default for all proofs.  You will have to turn it on for the proofs you want it on for. '
			},
			{
				date: '12/17/2019',
				title: 'Three line composites',
				content: 'You can now set a third line for your composites. ' +
					'From the Composer, click on the orange gear icon and then Subject Settings. ' +
					'Both student and staff displays now support 3 lines. ' +
					'<p/><img src="css/images/changelog/three-line-composites.png" style="height: 30em"/>'
			},
			{
				date: '12/13/2019',
				title: 'Studio Template',
				content: 'Studio templates now show in a layout grid like the Layouts tab. ' +
					'You have all of the same abilities you had before, plus the ability to move templates between categories. ' +
					'If you have a lot of templates in one category, you can also now page between them and search for specific ones. '
			},
			{
				date: '12/11/2019',
				title: 'Some proofing updates for Christmas',
				list: [
					'Updating the renders for a proof now only changes the approval status for denied proofs.  Approved proofs stay approved. ',
					'You can send proofs to multiple email recipients at the same time now. ',
					'View and mark proof comments completed directly from the Composer. ',
					'For new proofs only, changing the batch name in Subject Management will not remove the comments when you go to update the proof render.'
				]
			},
			{
				date: '11/27/2019',
				title: 'Subject Management: Bulk edit staff',
				content: 'You can now edit multiple subjects to be staff at the same time. ' +
					'From Subject Management, select multiple cards while holding Ctrl. ' +
					'Next click Options on any of the selected cards. ' +
					'Toggle the staff label or set a different priority, and the change will affect all of the selected subjects. ' +
					'Please note that this only applies to Staff and Staff Priority.  Other advanced options are still one at a time. '
			},
			{
				date: '11/12/2019',
				title: 'Customized proof invitations',
				content: 'When sending a proof invitation you can now add to or completey change the message. ' +
					'You can change the default proof message from the "Proof Invitation Message" text box in Studio/Lab Settings. ' +
					'You can further customize each individual proof invitation message when sending them out. '
			},
			{
				date: '10/15/2019',
				title: 'Proofs Viewed Indicator',
				content: 'If a proof has never been viewed, it will now show "Proof has not been viewed yet". ' +
					'After it has been viewed, a green "Viewed" label will show up in the upper right corner of the Proofs tab. ' +
					'If you hover over it, it will say when the proof was first and last viewed. ' +
					'If you view the proof while logged in, it will not record the proof as viewed. ' +
					'It will only record it as viewed if your customers view the proof OR if you open an incognito window in your browser. '
			},
			{
				date: '09/30/2019',
				title: 'Virtual Groups: Changing number of lines',
				content: 'When clicking on a subject in a virtual group, you can now change how many lines are in that group. ' +
					'You can set the number of lines to be between 2 and 10 lines. ' +
					'<p/><img src="css/images/changelog/virtual-groups-line-change.png" style="height: 20em"/>'
			},
			{
				date: '09/23/2019',
				title: 'Proofs: Toggling batch visibility',
				content: 'You can now toggle whether or not a batch is visible in your customers proof. ' +
					'From the proofs tab, look for the eye button near the bottom of the page. ' +
					'It will be green when visible and yellow when hidden from the proof page you give to your customers.'
			},
			{
				date: '09/10/2019',
				title: 'Locking individual proof pages',
				content: 'When a customer submits corrections or approves a proof, it will now be locked. ' +
					'You as the studio can unlock it from your Proofs tab if the customer mistakenly clicked on it. ' +
					'At the bottom of your proof tab where is says approved or denied, there is an unlock button that will unlock it.'
			},
			{
				date: '08/19/2019',
				title: 'Completing proof comments',
				content: 'You can now mark proof comments as completed. ' +
					'Once they have been marked completed you can hover over the green checkmark to see who completed it and when.'
			}
		]);
	}

	if($.plicLicenseFeatures['parent-subject-uploader']) {
		$.merge(changelog, [
			{
				date: '02/03/2021',
				title: 'Parent Subject Uploader: Review denied subjects',
				content: 'There is now a button when viewing parent uploaded subjects to review previously denied subjects. ' +
					'You can review and approve them if you did not mean to deny them originally. '
			},
			{
				date: '10/21/2020',
				title: 'Parent Subject Uploader: Optional or required email/phone numbers',
				content: 'Email and phone number is as an optional field for the parent subject uploader. ' +
					'There is also now a Project Setting to make the email or phone number a required field when parent\'s upload their kids photos. '
			},
			{
				date: '09/30/2020',
				title: 'Parent Subject Uploader: Turn off and on per project',
				content: 'There is now a Studio/Lab Setting to control whether this feature defaults to on or off for all of your projects (defaults to on). ' +
					'Next, in Project Settings you can toggle the parent subject uploader on and off for each project individually.'
			}
		]);
	}

	if($.plicLicenseFeatures['sheets-enabled']) {
		$.merge(changelog, [
			{
				date: '11/01/2021',
				title: 'Sheets: Separate layout vs sheet backgrounds',
				content: 'Previously, when you added a background to a sheet it would always add it as a sheet background. ' +
					'Now, when you add a background while viewing the entire sheet, it will add it as a sheet background. ' +
					'When adding the background while designing the individual cell layout, it will add a per cell background. ' +
					'You can use both a cell and sheet background together if the cell background has transparency in it. '
			}
		]);
	}

	$.merge(changelog, [
		{
			date: '02/23/2023',
			title: 'Render layout: Only render one page per grade, class, or other group',
			content: 'When rendering a layout or sheet, there is a new option called "Only render first subject from each group set". ' +
				'When selected, only one subject will be rendered for each matching Group By field set. ' +
				'For example, if you set to group by Grade, then only one subject from each grade will be rendered. ' +
				'This can be used to generate per class or group renders instead of per subject renders. '
		},
		{
			date: '01/17/2023',
			title: 'Render layout saved configurations',
			content: 'You can now save your filters and settings for rendering layouts and sheets. ' +
				'After setting up your filters the way you want them, press on Save and give it a name. ' +
				'Next time you go to render a layout you can simply select it from the dropdown instead of re-creating the same filters again. ' +
				'<p/><img src="css/images/changelog/render-layout-config.png" style="width: 100%"/>'
		},
		{
			date: '10/20/2022',
			title: 'Rotating drop shadow',
			content: 'You can now control which direction the drop shadow points towards for images. ' +
				'The drop shadow continues to default to pointing to the bottom right. ' +
				'There is a slider under the drop shadow menu to control it\'s direction. ' +
				'When rotating an image, it will now auto rotate to stay pointing to the bottom right instead of rotating with the image. ' +
				'<p/><img src="css/images/changelog/drop-shadow-rotation.png" style="width: 100%;"/>'
		},
		{
			date: '03/16/2022',
			title: 'Assigning layouts directly from the Layouts tab',
			content: 'You can now assign layouts directly from the Layouts tab. ' +
				'Click on the layout\'s dropdown button, and then click on Assign Layout to get started. ' +
				'The school\'s assigned to a layout will also show directly on the layout card for easier access. ' +
				'<p/><img src="css/images/changelog/layout-tab-assignments.png" style="width: 100%"/>'
		},
		{
			date: '03/10/2022',
			title: 'Subject pose cropping',
			content: 'If you want to crop a subject pose but do not want to crop each subject photo individually, you can now set a general pose crop in the layout or sheet. ' +
				'Click on a subject pose node and then click on the crop button.  This will allow you to set a general crop. ' +
				'Now when any subject is rendered with this layout, they will be cropped to the same size and position. ' +
				'This option will only show up after you have selected a subject with a photo to view what the crop will look like. ' +
				'This option also overrides the subject\'s photo crop. ' +
				'<p/><img src="css/images/changelog/subject-pose-crop.png" style="width: 100%"/>'
		},
		{
			date: '09/20/2021',
			title: 'New layout uploader',
			content: 'The layout uploader has been redesigned with a live preview and better error reporting. ' +
				'Now when you select a folder to upload, you will get a live preview of the layouts that are going to be uploaded. ' +
				'If the layout is linked to missing images, you will be warned about it before you start the upload. ' +
				'Our uploader continues to support both Flow lyt files and ProServices tem files. ' +
				'<p/><img src="css/images/changelog/layout-uploader.png" style="width: 100%"/>'
		},
		{
			date: '05/25/2021',
			title: 'Manually entering image position and size',
			content: 'There is a new edit position and size button on the image toolbar. ' +
				'You can use this to manually enter the x, y, width, and height of an image or placeholder. ' +
				'<p/><img src="css/images/changelog/manual-image-dimensions.png" style="width: 100%"/>'
		},
		{
			date: '05/12/2021',
			title: 'Drag to select content',
			content: 'You can now click and drag to select image and text nodes on a page. '
		},
		{
			date: '04/28/2021',
			title: 'Render layout dialog improvements',
			content: 'The render layout dialog has been improved with new filters. ' +
				'We have added new filters for Starts With, Ends With, Greater Than, Less Than, Is Blank, and Is Present. ' +
				'You can also filter on a specific number of photos instead of just has or does not have photos like before. ' +
				'The added since date filter has similarly been broken out into separate Subject Created At and First/Last Photo Uploaded At filters which you can do greater than or less than filters on. ' +
				'Last, but not least, you can now preview the subjects in a table which you are going to be printing layouts for. ' +
				'<p/><img src="css/images/changelog/render-layout-dialog.png" style="height: 30em"/>'
		},
		{
			date: '11/10/2020',
			title: 'Crop alignment in layouts',
			content: 'You can now edit the crop alignment for a layout\'s subject pose. ' +
				'Subject poses still default to the vertical top and horizontal center. ' +
				'You can change the horizontal and vertical alignment to be the start, center, or end of the image. '
		},
		{
			date: '05/27/2020',
			title: 'Use ordered backgrounds in layouts',
			content: 'In layouts you can now specify for subject images to use the ordered background with any pose. ' +
				'Previously, clicking on the green toggle would switch between not using any background or using the project background. ' +
				'Now clicking on it will bring up a dropdown where you can select between using the project background or the ordered background. ' +
				'Similar to the previous update, this will only work for order data which exist in PLIC. ' +
				'<p/><img src="css/images/changelog/ordered-background-layout.png" style="width: 100%"/>'
		},
		{
			date: '05/25/2020',
			title: 'Customers working on layouts',
			content: 'You can now allow your customers to edit layouts that you have assigned to them. ' +
				'Two new user roles have been created: School Admin and School User. ' +
				'Both roles are limited to viewing and editing layouts that have been assigned to them. ' +
				'All school users will default to School Admin from now on. ' +
				'When creating users you are now prompted to select which organization to create the user for. ' +
				'If you have existing PLIC Go users that you want to access PLIC Elements, you will need to edit their access to allow it from the Users tab. '
		},
		{
			date: '05/08/2020',
			title: 'Layout zip renders sort order',
			content: 'Layout zip renders now have filename prefixes so they will print in the specified sort order. ' +
				'Previously they would default to "Last Name, First Name-id.jpg". ' +
				'Now they will default to "000-Last Name, First Name-id.jpg"'
		},
		{
			date: '05/04/2020',
			title: 'PLIC Orders in layouts',
			content: 'You can now reference order information in a layout as long as it is an order that exists in PLIC. ' +
				'If you go to plic.io, and look under Studio Orders in a project you can see whether the orders exist on PLIC. ' +
				'We have updated the list of dynamic variables at http://help.photolynx.com/en/articles/3746308-plic-elements-dynamic-variables to include the new order specific variables. ' +
				'You can also show the ordered photo by selecting a subject photo and changing the pose from Primary Pose to Ordered Photo #. ' +
				'When rendering a layout with order info in it, you can choose whether to filter to only subjects with orders (on by default). '
		},
		{
			date: '04/23/2020',
			title: 'Dynamic graphics on layouts',
			content: 'For a long time, you have been able to import dynamic graphics from Flow and Pro Services layouts. ' +
				'Now you can create and edit dynamic graphics directly from PLIC Elements. ' +
				'On the Content tab there is a new option for dropping dynamic graphics onto a page. ' +
				'There is a new option on the toolbar for dynamic graphics to edit the mappings. ' +
				'<p/><img src="css/images/changelog/dynamic-graphics.jpg" style="width: 100%"/><p/>' +
				'From there you can add or remove as many field/value => graphic mappings as you want. ' +
				'<p/><img src="css/images/changelog/dynamic-graphics-dialog.png" style="width: 100%"/>'
		},
		{
			date: '03/02/2020',
			title: 'Group alignment tools',
			content: 'When more then one image is selected, a new option for group alignment shows up on the toolbar. ' +
				'You can now align a group of images to the left, right, top, bottom, or center vertically or horizontally. ' +
				'You can also space them evenly horizontally or vertically. ' +
				'The last new option is to make all of the images the same size as the main one you have selected. ' +
				'<p/><img src="css/images/changelog/alignment-tools.png" style="width: 100%"/>'
		},
		{
			date: '01/31/2019',
			title: 'Group Pose Selection',
			content: 'For Layouts, you can now select Group Photo 1-5 for the subject pose. ' +
				'This will only show the group photo if it is marked as "Group" on PLIC. '
		},
		{
			date: '11/05/2019',
			title: 'Rendering layouts for subjects after certain date',
			content: 'When rendering layouts or sheets, you can now filter to only subjects that are added after a certain date. ' +
				'This will include both subjects that were created after the filter date AND subjects that had new photos added to them after the filter date. ' +
				'<p/><img src="css/images/changelog/render-subjects-added-since.png" style="height: 30em"/>'
		},
		{
			date: '09/13/2019',
			title: 'Text Opacity',
			content: 'We have added support for text opacity. ' +
				'Importing text opacity from Flow layouts is also supported now. ' +
				'<p/><img src="css/images/changelog/text-opacity.png" style="height: 20em"/>'
		},
		{
			date: '09/03/2019',
			title: 'Render without subjects with no photos',
			content: 'When printing both layouts and sheets, you can now control whether to include subjects without photos. ' +
				'For layouts it will default to not including subjects without photos. ' +
				'For sheets, it will default to what it is set to be in the sheet\'s settings. ' +
				'<p/><img src="css/images/changelog/render-layout-subjects-without-photos.png" style="height: 20em"/>'
		},
		{
			date: '08/09/2019',
			title: 'Layout rendering tools',
			content: 'When rendering layouts from the Composer, you can now select a sort order as well as add filters. ' +
				'If you have subjects selected on the top bar, the filters will only be applied to those selected subjects and not the whole project. ' +
				'For those with Sheets functionality we have also added the ability to render a ZIP of sheets instead of only allowing PDFs.' +
				'<p/><img src="css/images/changelog/layout-sort-options.png" style="height: 20em"/>'
		},
		{
			date: '07/31/2019',
			title: 'Season switching',
			content: 'You can now switch between seasons directly from the top menu on the Management screen. ' +
				'If you have multiple seasons the season display will automatically change to show as a dropdown. ' +
				'<p/><img src="css/images/changelog/season-picker.jpg" style="height: 20em"/>'
		},
		{
			date: '07/31/2019',
			title: 'Double sided layouts',
			content: 'You can now create and print double sided ID cards. ' +
				'From the Composer go to Content and then drag the "Add back side" label onto the layout. ' +
				'From there you will be able to switch between the front and back sides and add whatever content you want to both sides. ' +
				'When you go to render the layouts it will render the front and back side for each subject rendered. ' +
				'<p/><img src="css/images/changelog/double-sided-layouts.jpg" style="height: 20em"/>'
		},
		{
			date: '07/19/2019',
			title: 'Simplified composite creation',
			content: 'The previous process of creating a school organization, then creating a project, then creating a school advisor account has been simplified. ' +
				'The Create Organization button has been replaced with Create Studio button if you are a lab and have permission to create studio organizations. ' +
				'The Create Project button has been replaced by a Create Composite button. ' +
				'When you click on it, you will be walked through the process of creating a composite project. ' +
				'During this process you will be prompted to create a new school organization if one did not exist already. ' +
				'If you are creating a new organization, you will also be prompted to automatically create a user with the school\'s contact info. ' +
				'<p/><img src="css/images/changelog/create-composite-wizard.png" style="height: 20em"/>'
		},
		{
			date: '07/10/2019',
			title: 'Virtual Groups (BETA) with minor subject adjustments',
			content: 'For the past couple of months there have been a few basic virtual group layouts to work with. ' +
				'Today we are adding the ability to make adjustments to the subject images in a virtual group without going into the crop screen. ' +
				'When selecting a subject you can now use your mouse or the arrow keys to move subjects around to make them all line up. ' +
				'You can also resize subjects using the handles on the edges or by using your mouse\'s scroll wheel. ' +
				'These options will only show up for new projects or after you have dragged one of the virtual group layouts onto the batch again. '
		},
		{
			date: '07/08/2019',
			title: 'Composites with non-standard aspect ratios',
			content: 'Composites are no longer required to be a 8 x 10 aspect ratio. ' +
				'If you upload all of your subject images as 5 x 7s for example, then the layout will automatically adjust to make a composite of 5 x 7s. ' +
				'Please note that you still cannot mix and match images of different aspect ratios in the same project. ' +
				'All of the images will be forced to match the most common aspect ratio of the uploaded subject images. ' +
				'Subject Management will default to showing an error about not being an 8 x 10 aspect ratio until a layout has been applied on the Composer to override it. '
		},
		{
			date: '06/14/2019',
			title: 'Layouts',
			content: 'All users now have access to the new Layouts and Layout Assignments tabs. ' +
				'The Layouts tabs is for creating, editing, and managing stand alone layouts. ' +
				'Those who have paid for an Elements license will have access to render these layouts directly from the layout composer as well. ' +
				'<p/><img src="css/images/changelog/new-layouts-tab.png" style="height: 20em"/><p/>' +
				'<p/><img src="css/images/changelog/layout-composer.png" style="height: 26em"/><p/>' +
				'The Layout Assignments tab is for controlling which layouts customers are able to view and print in PLIC Go, Experience, and Match. ' + 
				'<p/><img src="css/images/changelog/new-layout-assignments-tab.png" style="height: 10em"/><p/>' +
				'For existing users of PLIC Layouts, these tabs function the same as they did in Layouts. ' +
				'Access to Sheets functionality to be able to create a sheet of layouts is available as an add-on purchase. '
		},
		{
			date: '05/02/2019',
			title: 'Content moving subjects when overlapping subject labels',
			content: 'A new project setting for called "Allow content to overlap subject labels" has been added. ' +
				'It defaults to off for new projects. All existing projects have it on to maintain the same behavior as before. ' +
				'When it is off, putting content over subject labels will move the subject cell just like when you put content over the subject photos. ' +
				'When it is on, putting content over subject labels will not move the subject cells.  This is how all projects behaved before today. ' +
				'This new setting is most important when using Apply To All and making sure different classes do not have any overlap. '
		},
		{
			date: '03/13/2019',
			title: 'User App Access',
			content: 'For those using multiple PLIC Apps, you now have the ability to control which users can access which apps. ' +
				'By default users created from PLIC Elements will have access too all apps. ' +
				'You can give users access to specific apps when creating them, or later from the Actions -> Edit Access menu on the Users tab. '
		},
		{
			date: '03/12/2019',
			title: 'Shapes',
			content: 'You can now create basic colored shapes for your Elements. ' +
				'Go to the Content section on the right sidebar, and drag the blue shape onto a page to get started. ' +
				'You can resize the shape to make lines, or drag shapes from the left sidebar to get the desired look. ' +
				'<p/><img src="css/images/changelog/custom-shapes.png" style="height: 30em"/>'
		},
		{
			date: '02/12/2019',
			title: 'Big Teacher Composites',
			content: 'A new layout has been added for big teacher composites.  It will show as many teachers as you have set in Subject Management. ' +
				'<p/><img src="css/images/changelog/big-teacher-composites.png" style="height: 40em"/><p/>'
		},
		{
			date: '02/07/2019',
			title: 'Download Proof Comments',
			content: 'From the proofs tab there is an option to download all of the comments. ' +
				'You can also see when a proof was approved or denied by hovering over the "Approved by..." label.'
		},
		{
			date: '02/04/2019',
			title: 'Graphic Overlays',
			content: 'You can now have graphics on top of subject images as well as behind them. ' +
				'From the toolbar, pressing "Move image forward" once will put the graphics on top of subjects and pressing "Move image back" will move the graphic behind the subjects.'
		},
		{
			date: '01/02/2019',
			title: 'Proof Submissions',
			content: 'In Studio/Lab Settings you can add emails you want to get notifications when proofs are submitted.  Happy New Years!'
		},
		{
			date: '12/07/2018',
			title: 'Proof Improvements',
			list: [
				'You can now update all proofs at once by updating to a whole project render such as an Elements preview',
				'Finalize and Submit is now only clickable when the customer has approved or denied each individual batch',
				'Finalize and Submit shows a list of batches that are still waiting to be reviewed',
				'Already reviewed proofs are highlighted red or green to differentiate between reviewed and unreviewed batches',
				'Proof batches are automatically sorted as if Mr. and Mrs. were not in the name instead of in render order'
			]
		},
		{
			date: '12/03/2018',
			title: 'Unlock Proofs',
			content: 'Studios have been given the ability to unlock proofs directly from the Proofs tab.  You can also download all of the proof renders into a single ZIP file if you want to print them.'
		},
		{
			date: '11/12/2018',
			title: 'Multiple Element Jobs',
			content: 'You can now create multiple element jobs for a single PLIC project. ' +
				'Subject data will be the same between both jobs, but batches and composites are separate. ' +
				'This enables you to have a whole school composite and a set of teacher composites at the same time. ' +
				'The best part is that name fixes and subject changes will be the same across both! ' +
				'You can create as many element jobs as you want by going to the project\'s dropdown and selecting "New Project Element"' +
				'<p/><img src="css/images/changelog/new-project-element.png"/><p/>' +
				'From there you will be asked for a unique name and how you want the subjects filtered. ' +
				'They will then show up in your Composites list as well as the top dropdown menu. ' +
				'You can change the filter by criteria from Subject Management if you change your mind later. ' + 
				'<p/><img src="css/images/changelog/new-project-element-dialog.png"/><p/>'
		},
		{
			date: '11/07/2018',
			title: 'Preview Proofs',
			content: 'You can now generate a proof from a preview render'
		},
		{
			date: '11/07/2018',
			title: 'Material Design',
			content: 'PLIC Elements has been redesigned with a new Material Design look and feel! ' +
				'Everything should still be familiar with your tabs on the left side and your content on the right.' +
				'<p/><img src="css/images/changelog/material-drawer-management.png"/><p/>'
		},
		{
			date: '09/14/2018',
			title: 'Text Justify Alignment',
			content: 'Under the text alignment options, you can now specify Justify.' +
				'<p/><img src="css/images/changelog/text-alignment-justify.png" style="height: 20em"/>'
		},
		{
			date: '09/04/2018',
			title: 'Proof Emails',
			content: 'You can now send proof emails to your customers directly from the Proofs tab ' + 
				'<p/><img src="css/images/changelog/send-proof-emails.png" style="height: 12em"/>'
		},
		{
			date: '08/30/2018',
			title: 'Organization/Project Short Codes',
			content: 'Organization and Project now support short codes. ' + 
				'These codes are a maximum of 9 characters, and can be used for the unique identifier you use in your own internal record keeping. ' +
				'When projects or organizations have a short code, it will show at the end of the name in Management.  Short codes are not required.'
		},
		{
			date: '08/27/2018',
			title: 'Subject Green Screen Backgrounds',
			content: 'Green screen projects will now automatically render with the default project background. ' +
				'Subject images must be marked with the chroma key `processed` and the project must have a default project background image set on PLIC.'
		},
		{
			date: '07/02/2018',
			title: 'Improved Resizing',
			content: 'We have improved how you resize image and text content so you can resize from any side and more easily grab the handles.' +
				'<p/><img src="css/images/changelog/reworked-resizing.png" style="height: 12em"/>'
		},
		{
			date: '05/29/2018',
			title: 'Per Batch Layouts',
			content: 'Layouts and Grid Sizes are now per batch options.  You can use Apply To All to apply the layout to the entire project. ' +
				'We have also added Layout Behaviors for quickly changing some of the most common Subject Settings by dragging them onto the page.'
		},
		{
			date: '05/23/2018',
			title: 'Zoom Controls',
			content: 'Zoom is here!  You can zoom up to 300% in order to get a closer look at your Elements. ' +
				'<p/><img src="css/images/changelog/zoom-controls.png" style="height: 12em"/>'
		},
		{
			date: '05/21/2018',
			title: 'Layout Templates',
			content: 'From the Options menu on the Composer, you can now save your Template for re-use later and in other projects! ' +
				'After you save them they will show up as an option under the Studio Templates menu on the left. ' +
				'You can delete or rename your Templates from the new Studio Templates tab on your Elements Management Dashboard.'
		},
		{
			date: '04/20/2018',
			title: 'Group content together',
			content: 'You can now group content together so they all default to being moved and edited together. ' +
				'<p/><img src="css/images/changelog/lock-elements-together.png" style="height: 20em"/><p/>'
		},
		{
			date: '04/19/2018',
			title: 'Apply to All for subject labels',
			content: 'You can now use the Apply Styles To All Page button for subject labels. ' +
				'<p/><img src="css/images/changelog/subject-label-apply-to-all.png" style="height: 20em"/><p/>'
		},
		{
			date: '04/18/2018',
			title: 'Duplicate content between pages',
			content: 'You now can duplicate images and text between different pages. ' +
				'<p/><img src="css/images/changelog/duplicate-content-pages.png" style="height: 20em"/><p/>'
		},
		{
			date: '04/18/2018',
			title: 'Subject Label Font Sizes',
			content: 'You can now tweak the subject label\'s font size in +-2% increments. ' +
				'<p/><img style="height: 20em" src="css/images/changelog/subject-label-font-size.png"/>'
		},
		{
			date: '03/29/2018',
			title: 'New Teacher Row Layout',
			content: 'A new layout has been added which has a row for just the teachers!' +
				'<p/><img style="height: 20em" src="css/images/changelog/teacher-only-row-layout.png"/>'
		},
		{
			date: '03/22/2018',
			title: 'Home Room Support',
			content: 'For projects with Home Room data and no Teacher data, the Home Room will be shown instead of Teacher on Subject Management'
		},
		{
			date: '03/08/2018',
			title: 'Subject Label Styles',
			content: 'You can now tweak bold, italic, and unerline for subject labels'
		},
		{
			date: '03/02/2018',
			title: 'App Visibility Settings',
			content: 'For those using multiple PLIC Apps, you now have the ability to control where projects show up.1 ' +
				'If you want to have a project show up only for Books, or for Books and Elements, or for all your PLIC Apps, the choice is up to you! ' +
				'Go to Project Settings to edit your project\'s visibility.'
		},
		{
			date: '01/02/2018',
			title: 'Proof Workflows',
			content: 'A new tab for Proofs has been added.  First generate a proof from your rendered Elements.  If you render individual batches you can select multiple submissions to generate a Proof from.  ' +
				'Next copy the customer URL in the text box at the top and email it to your customer for proofing.  They will be asked to approve, deny, or comment on each proof.  ' +
				'You can update any denied proofs with a new render and ask customers to re-review them.'
		},
		{
			date: '12/08/2017',
			title: 'Large Submission Performance Enhancement',
			content: 'Large submissions have been optimized to render faster with subject stroke and drop shadow'
		},
		{
			date: '11/21/2017',
			title: 'Subject Management Improvements',
			list: [
				'Filter and sort by multiple fields.  For example, you can now create batches by both the Teacher and the Grade of subjects',
				'View and edit crops directly from Subject Management',
				'Ability to add Grades and Teachers which did not previously exist',
				'Studios can now start creating subjects from scratch.  Note that school users will still be required to wait for subjects to be uploaded first',
				'Customers are given warnings when they are uploading low resolution photos which will not look good'
			]
		},
		{
			date: '11/02/2017',
			title: 'Improved Whole School Composites',
			content: 'The behavior for large whole school composites has been dramatically improved to waste less space'
		},
		{
			date: '10/19/2017',
			title: 'Per Batch Teacher Priority',
			content: 'Subjects can have a different Teacher Priority in each batch now.  You can copy a subject from one batch to another and have them marked as only a teacher in Batch A but not in Batch B.'
		},
		{
			date: '09/20/2017',
			title: 'Turn off centering of subjects',
			content: 'We have added a new Project Setting to turn off and on the centering of the last row of subjects.  This setting can also be accessed from Subject Settings in the composer.' +
				'<p/><p/>You can also move text outside of the margins now.'
		},
		{
			date: '09/08/2017',
			title: 'Grid Lines',
			content: 'From the Composer you can now toggle on and off grid lines.  From the sidebar go to My Settings and you can turn grid lines on or off, as well as tweak their frequency and color.' +
				'<p/><img src="css/images/changelog/grid-lines-option.png"/>'
		},
		{
			date: '08/29/2017',
			title: 'Get more done in version 3!',
			list: [
				'Multiple users can edit projects at the same time and you will see their changes as they make them!',
				'We have reworked our text and candids to give you the options you need without any of the fuss',
				'You can now easily switch which School you are looking at from the top of the Management page',
				'Manage Seasons and which Schools are currently visible from Studo/Lab\'s Settings',
				'Custom filtering and sorting from Subject Management',
				'Custom workflows you can tweak and customize to drive creating Elements',
				'Embedded subject cropping tools',
				'Tools and reports to aid you in Quality Assurance',
				'Tons more subtle tweaks and enhancements!'
			]
		}
	]);

	return changelog;
};
