import populateFullName from "../utils/populate-full-name";

// Work around for tests not being run through webpack - once we do we can just directly reference process.env
if(process.env.VUE_APP_IMAGEQUIX_APP_ID) {
	$.IMAGEQUIX_APP_ID = process.env.VUE_APP_IMAGEQUIX_APP_ID
}

$.SubjectManagement = function(options) {
	options = $.extend(true, {
		allowClearTrash: true,
		updateSubjectsFromBatchChanges: true
	}, options);

	var div = $('<div class="subjectManager">')[0];

	$.extend(div, {
		loadSchools: function (startJobId) {
			this.loader.addClass('active');
			$(this.schoolDropdown).find('.menu').empty();

			if($(this.schoolDropdown).isAttached()) {
				$(this).append(this.schoolDropdown);
			}

			var me = this;
			$.ajax({
				url: 'ajax/getSchools.php',
				dataType: 'json',
				type: 'POST',
				data: {
					// withSubjects: true
				},
				success: function (data) {
					var startIndex = 0;
					for (let i = 0; i < data.length; i++) {
						if (data[i].subjects) {
							startIndex = i;
							break;
						}
					}

					me.noProjectsMessage.hide();
					if (data.length > 1) {
						var list = me.schoolDropdown.find('.menu');
						for (let i = 0; i < data.length; i++) {
							var job = data[i];
							me.subjects = job.subjects = null;
							$('<div class="item">')
								.attr('data-value', data[i].jobId)
								.text(data[i].jobName)
								.data('job', job)
								.appendTo(list);

							if (startJobId && job.jobId == startJobId) {
								startIndex = i;
							}
						}

						list.find('.batchItem').eq(startIndex).addClass('active selected');
						me.schoolDropdown.dropdown({
							onChange: function (value, text, obj) {
								var job = obj.data('job');
								me.loadSchool(job);
							},
							fullTextSearch: 'exact',
							match: 'text'
						});

						let startSchool = data[startIndex];
						me.schoolDropdown.dropdown('set selected', startSchool.jobName);
					} else if (data.length) {
						let startSchool = data[0];
						startSchool.subjects = null;
						me.schoolLabel = $('<div class="ui big label">').text(startSchool.jobName).prepend('<i class="university icon">');
						me.schoolDropdown.replaceWith(me.schoolLabel);

						me.loadSchool(startSchool);
					} else {
						me.stickyBatchHeader.hide();
						$(me.batch).hide();
						me.noProjectsMessage.show();
					}

					if (me.onLoadSchools) {
						me.onLoadSchools();
					}
					me.cachedSchools = data;
					me.loader.removeClass('active');
				}
			});
		},
		refreshSchool: function(fullRefresh) {
			if(fullRefresh) {
				this.loadSchool(this.job, true);
			} else {
				var job = this.job;
				this.job = null;
				var startBatch = this.currentBatch;
				this.batchList.clear();
				this.loadSchool(job);

				if(!$.userExtras.lastLoadedBatches) {
					$.userExtras.lastLoadedBatches = {};
				}
				$.userExtras.lastLoadedBatches[job.jobId] = startBatch.id;
			}
		},
		loadSchool: function(job, forceUpdate) {
			if(!job) {
				this.noProjectsMessage.show();
				return;
			} else if(forceUpdate) {
				delete job.subjects;
				delete job.unmatchedSubjects;
				delete job.batches;
				delete job.fieldValues;
				this.batchList.clear();
				delete this.batchList.currentType;

				for(var name in this.batchList.cachedData) {
					if(name.indexOf(this.job.jobId + '') != -1) {
						this.batchList.cachedData[name] = null;
					}
				}
			} else if (job === this.job) {
				return;
			}

			this.loadingSchool = true;
			this.searchBoxInput.attr('disabled', true);
			this.noProjectsMessage.hide();
			this.dbQueue.closeWebSocket();
			this.dbQueue.postData.jobId = job.jobId;
			this.batchList.editable = false;
			if(job) {
				this.editable = true;
				this.job = job;
				this.jobId = job.jobId;
				this.batch.setJob(job);

				if (job.status && job.status != 'In Work' && job.status != 'Rework' && job.status != '-' && job.status != 'Storyboard') {
					this.editable = false;
				}

				this.stickyBatchHeader.show();
				$(this.batch).show();
				this.emptyMessage.hide();

				// Turn off automatic subject changes with BP integration since the next sync will change it back which will trigger moving batches when it shouldn't
				if($.IMAGEQUIX_APP_ID) {
					this.updateSubjectsFromBatchChanges = job.createdByPlicAppId !== $.IMAGEQUIX_APP_ID;
				}

				try {
					$.globalBugsnagInfo['School'] = $.extend({}, job);
				} catch (e) {
					console.error('Failed to add to globalBugsnag info');
				}
			} else {
				this.editable = false;
			}
			this.requireSchoolButtons.addClass('disabled');
			this.requireEditableSchoolButtons.addClass('disabled');
			if(this.subjectIssuesButton) {
				this.subjectIssuesButton.remove();
				this.subjectIssuesButton = null;
			}

			if (this.onCheckSchoolEditable) {
				this.onCheckSchoolEditable(job);
			}
			if (this.editable) {
				this.lockedMessage.hide();
			} else {
				this.lockedMessage.text(this.lockedMessageText).show();
			}

			this.batch.setEditable(this.editable);
			if (this.onBeforeLoadSchool) {
				this.onBeforeLoadSchool(job);
			}
			this.batches = null;

			var me = this;
			this.startLoading();
			if(job.batches) {
				this.finishLoadingBatches(job.batches);
			} else {
				$.proxyAjax({
					url: 'ajax/getBatches.php',
					data: {
						jobId: this.jobId,
						allowEmpty: true
					},
					type: 'POST',
					success: function(data) {
						// We switch jobs while this was loading
						if(me.job !== job) {
							return;
						}

						me.finishLoadingBatches(data.results);
						me.finishLoadingSchool(job);
					},
					error: function() {
						me.error();
					}
				});
			}

			if(this.parentSubjectUploadButton) {
				this.parentSubjectUploadButton.job = job;
			}

			// Load other details even if batches are cached
			this.getJobSubjects(job);
			this.loadSubjectAlbum(job);
			this.getJobData(job);

			// Run finish loading in case we already cached all this data
			this.finishLoadingSchool(job);
		},
		finishLoadingBatches: function(batches) {
			batches.batchNameSort();
			batches.forEach(function(batch) {
				batch.active = false;
				batch.searching = false;
			});

			this.dbQueue.classes = this.job.batches = this.batches = this.batchList.batches = batches;
			this.onFinishLoadBatch = batches[0];

			var lastLoadedBatches = $.userExtras.lastLoadedBatches || {};
			var lastLoadedBatch = lastLoadedBatches[this.jobId];
			if(lastLoadedBatch) {
				var startClass = batches.filter(function(batch) {
					return batch.id == lastLoadedBatch;
				})[0];

				if(startClass) {
					this.onFinishLoadBatch = startClass;
				}
			}

			this.batchList.stopLoading();
			var me = this;
			this.batchList.$nextTick(function() {
				me.batchList.$nextTick(function() {
					me.initializeSticky();
				});
			});
		},
		initializeSticky: function() {
			if (this.stickyInitialized) {
				this.stickyBatchHeader.sticky('refresh');
			} else {
				this.stickyBatchHeader.sticky({
					offset: 4,
					scrollContext: window.visibilityScrollRelativeTo || $('body')
				});
			}
			this.stickyInitialized = true;
		},
		removeSchool: function (jobId) {
			var menuItem = this.schoolDropdown.find('.menu > .item[data-value="' + jobId + '"]');

			menuItem.remove();
			if (this.jobId == jobId) {
				this.schoolDropdown.dropdown('set value', '');
				this.schoolDropdown.dropdown('set text', '');

				this.job = null;
				this.jobId = null;

				this.stickyBatchHeader.hide();
				$(this.batch).hide();

				this.requireSchoolButtons.addClass('disabled');
				this.searchBoxInput.attr('disabled', true);

				this.editable = this.batchList.editable = false;
				this.batch.setEditable(false);
				this.batchList.clear();
				this.batch.clear();
			}
		},
		getJobSubjects: function (job) {
			if (job.subjects) {
				return;
			}

			var me = this;
			$.loadSubjects({
				plicProjectId: job.plicProjectId,
				loadTemplate: true,
				width: 100,
				onComplete: function(subjects, projectTemplate) {
					job.subjects = subjects;
					job.template = projectTemplate;

					if(me.job !== job) {
						return;
					}
					me.subjects = subjects;
					me.finishLoadingSchool(job);
				},
				onError: function() {
					me.error();
				}
			});
		},
		getJobData: function(job) {
			if(job.projectDetails) {
				return;
			}

			$.plicAPI({
				method: 'projects/' + job.plicProjectId,
				type: 'GET',
				success: (data) => {
					job.projectDetails = data.project;
					if(this.job !== job) {
						return;
					}

					this.finishLoadingSchool(job);
				}
			});
		},
		loadSubjectAlbum: function (job) {
			if (job.subjectAlbumId) {
				this.batch.setSubjectAlbum(job.subjectAlbumId);
				return;
			}

			var me = this;
			$.plicAPI({
				method: 'albums',
				params: {
					parent_resource_type: 'project',
					parent_resource_id: job.plicProjectId,
					tag: 'Subject Photos'
				},
				type: 'GET',
				success: function (data) {
					var albums = data.albums;

					if (albums.length) {
						var albumId = albums[0].id;
						job.subjectAlbumId = albumId;

						if(me.job !== job) {
							return;
						}
						me.batch.setSubjectAlbum(albumId);
						me.finishLoadingSchool(job);
					} else {
						me.createSubjectAlbum(job, 1);
					}
				},
				error: function() {
					me.error();
				}
			});
		},
		createSubjectAlbum: function(job, attempt) {
			var me = this;
			$.plicAPI({
				method: 'albums',
				params: {
					album: {
						name: 'Subject Photos' + (attempt > 1 ? attempt : ''),
						tags: [
							'Subject Photos'
						]
					},
					parent_resource_type: 'project',
					parent_resource_id: job.plicProjectId
				},
				success: function (data) {
					var albumId = data.album.id;
					job.subjectAlbumId = albumId;
					if(me.job !== job) {
						return;
					}

					me.batch.setSubjectAlbum(albumId);
					me.finishLoadingSchool(job);
				},
				error: function() {
					if(attempt < 3) {
						me.createSubjectAlbum(job, attempt + 1);
					}
				}
			});
		},
		finishLoadingSchool: function (job) {
			// Cancel when not finished loading or job has changed
			if (!job.subjects || !job.batches || !this.batches || !job.template || !job.subjectAlbumId || job != this.job || !job.projectDetails) {
				return;
			}

			if(this.job.projectType && $.projectTypeFieldSets && $.projectTypeFieldSets[this.job.projectType]) {
				this.job.fieldSet = $.extend(true, {}, $.projectTypeFieldSets[this.job.projectType]);
			} else {
				this.job.fieldSet = $.extend(true, {}, $.SubjectManagement.DEFAULT_PROJECT_FIELD_SETS);
			}
			this.setupCustomFilters();

			this.loadingSchool = false;
			this.batchList.editable = this.editable;
			if((job.subjects.length || this.startFromScratch) && !this.hideSubjects) {
				this.setupFieldValues(job);
				this.setupSubjectsWithData(job);

				if(job.subjects.length || (job.placeholderSubjects?.length && this.batches.length) || this.startFromScratch) {
					if(this.batches.length || (!job.subjects.length && this.startFromScratch)) {
						if(!job.unmatchedSubjects) {
							var startBatchSubjects = job.batches.reduce(function(total, batch) {
								return total + (batch.subjectCount || 0);
							}, 0);

							job.unmatchedSubjects = $.SubjectManagement.addSubjectsToBatches(job.batches, job.subjects, job.placeholderSubjects, job, {
								success: function(data) {
									if(data.newBatches) {
										data.newBatches.forEach(function(batch) {
											div.insertBatchDiv(batch);
										});
									} else if(data.updatedBatches) {
										data.updatedBatches.forEach(function(batch) {
											div.updateBatchDivCount(batch);
										});
									}
								}
							});
							// Allow placeholders to be in trash
							if(job.placeholderSubjects) {
								$.merge(job.unmatchedSubjects, job.placeholderSubjects);
								$.SubjectManagement.sortSubjects(job.unmatchedSubjects, this.job.sortBy);
							}

							if(job.subjects.length && job.unmatchedSubjects.length === (job.subjects.length + (job.placeholderSubjects ? job.placeholderSubjects.length : 0))) {
								// If we had batches with subjects in them, this was probably a wipe and re-upload
								if(startBatchSubjects > 0) {
									// console.log('recreateBatchFilter due to unmatchedSubjects === subjects length: ' + job.subjects.length + ' vs ' + job.unmatchedSubjects.length);
									this.recreateBatchFilter($.SubjectManagement.getDefaultFilter(job));
									return;
								}
							}
						}
						this.searchBoxInput.attr('disabled', false);

						if(!this.onFinishLoadBatch) {
							this.onFinishLoadBatch = this.getTrashBatch();
						}

						var showFields = {}, hideFields = {};
						this.job.fieldSet.editableFields.forEach(function(jobField) {
							if(jobField.ifInData) {
								showFields[jobField.name] = !!job.subjects.find(function(subject) {
									return !!$.trim(subject[jobField.name]);
								});
							} else if(jobField.ifInDataWithout) {
								var otherFieldHasData = !!job.subjects.find(function(subject) {
									return !!$.trim(subject[jobField.ifInDataWithout]);
								});
								if(otherFieldHasData) {
									hideFields[jobField.name] = true;
								} else {
									hideFields[jobField.ifInDataWithout] = true;
								}
							}
						});
						this.showFields = showFields;
						this.hideFields = hideFields;

						this.updateBatchesWildcardRatios();
						this.loadBatch(this.onFinishLoadBatch);
						this.setBatchVisible();
						this.updateSubjectIssuesButton();
						this.updateResortSubjectsButton();

						this.updateBatchDivCount(this.getTrashBatch());
						this.dbQueue.plicProjectId = this.job.plicProjectId;
						if($.subjectManagementWebSocket) {
							console.warn('open web socket');
							this.dbQueue.openWebSocket();
						}
					} else {
						// Auto create batches
						var filter = $.SubjectManagement.getDefaultFilter(job);
						if(job.groupBy && job.sortBy) {
							filter = job.groupBy + ' and sort by ' + job.sortBy;
						}

						// console.log('recreateBatchFilter due to batches = 0');
						this.recreateBatchFilter(filter);
						return;
					}
				} else {
					// This should only happen if no valid subjects (ie: none with photo assigments)
					this.finishLoadingSchool(job);
					return;
				}

				if(this.editable) {
					this.requireSchoolButtons.removeClass('disabled');
					this.requireEditableSchoolButtons.removeClass('disabled');
				}
			} else {
				this.stickyBatchHeader.hide();
				$(this.batch).hide();
				this.emptyMessage.show();

				this.requireSchoolButtons.addClass('disabled');
				if(this.editable) {
					this.requireEditableSchoolButtons.removeClass('disabled');
				}
				this.searchBoxInput.attr('disabled', true);

				this.editable = this.batchList.editable = false;
				this.batch.setEditable(false);
				this.batchList.clear();
				this.batch.clear();
				this.updateSubjectIssuesButton();
				this.updateResortSubjectsButton();
			}

			if (this.onLoadSchool) {
				this.onLoadSchool(this.job, this.batches);
			}
		},
		updateBatchesWildcardRatios() {
			// Logic needs to match what we do in subject-grid-fit.js -> getCellRatio
			this.job.batches.forEach(batch => {
				if(batch.aspectRatio !== '*' || !batch.subjects.length) {
					return;
				}

				let ratios = {
					'0.8': 0
				};
				batch.subjects.forEach(function(user) {
					let photo = user.yearbookPhoto;
					if(!photo || !photo.width || !photo.height) {
						return;
					}

					let photoWidth = photo.width;
					let photoHeight = photo.height;

					if(photo.yearbookCrop) {
						photoWidth = photoWidth * photo.yearbookCrop.width;
						photoHeight = photoHeight * photo.yearbookCrop.height;
					}

					// Only want two digits of precision
					let ratio = Math.round(photoWidth / photoHeight * 100) / 100;
					if(ratios[ratio]) {
						ratios[ratio]++;
					} else {
						ratios[ratio] = 1;
					}
				});

				var maxRatio = 0.8;
				var maxRatioUsers = 0;
				for(var ratio in ratios) {
					if(ratios[ratio] > maxRatioUsers) {
						maxRatio = parseFloat(ratio);
						maxRatioUsers = ratios[ratio];
					}
				}

				batch.aspectRatio = maxRatio;
			});
		},
		setupCustomFilters: function() {
			if(!this.filterDropdown) {
				return;
			}

			var filterDropdownMenu = this.filterDropdown.children('.menu');
			filterDropdownMenu.find('.item').remove();
			var filterByOptions = this.job.fieldSet.filterByOptions || [
				{
					name: 'Default (guess based on teacher/grade data)',
					color: 'green'
				},
				{
					name: 'Teacher',
					color: 'red'
				},
				{
					name: 'Grade',
					color: 'blue'
				},
				{
					name: 'Grade and sort by Teacher',
					color: 'yellow'
				},
				{
					name: 'Teacher,Grade',
					display: 'Teacher and Grade',
					color: 'purple'
				}
			];
			if(options && options.extraFilterByOptions) {
				$.merge(filterByOptions, options.extraFilterByOptions);
			}
	
			filterByOptions.push({
				name: 'Custom filter',
				color: 'teal'
			});
	
			for(let i = 0; i < filterByOptions.length; i++) {
				var option = filterByOptions[i];
				var optionName;
				if(option.name) {
					optionName = option.name;
				} else {
					optionName = option;
				}
				var optionDisplay = optionName;
				if(option.display) {
					optionDisplay = option.display;
				}
	
				var optionItem = $('<div class="item">')
					.attr('data-value', optionName)
					.text(optionDisplay);
	
				if(option.color) {
					$('<div class="ui empty circular label">').addClass(option.color).prependTo(optionItem);
				}
	
				filterDropdownMenu.append(optionItem);
			}
		},
		updateSubjectIssuesButton: function() {
			var subjectsWithIssues = this.getSubjectsWithIssues();

			if(subjectsWithIssues.length) {
				if(!this.subjectIssuesButton) {
					this.subjectIssuesButton = $('<div class="ui red icon labeled button">').click(function() {
						if($(this).text() != 'Stop Reviewing') {
							div.filterForSubjects(div.getSubjectsWithIssues());
							$(this).html('<i class="ui remove icon"/>').append('Stop Reviewing');
						} else {
							div.stopSearch();
						}
					}).insertBefore(this.searchBox);
				}
				this.subjectIssuesButton.html('<i class="ui warning sign icon"/>').append('Review ' + subjectsWithIssues.length + ' Subjects');

			} else if(this.subjectIssuesButton) {
				$(this.subjectIssuesButton).remove();
				this.subjectIssuesButton = null;
			}
		},
		getSubjectsWithIssues: function(includeUnmatched) {
			var subjectsWithIssues = [];
			if(!this.job || !this.job.subjects || !this.job.unmatchedSubjects) {
				return subjectsWithIssues;
			}

			for(let i = 0; i < this.job.subjects.length; i++) {
				let subject = this.job.subjects[i];
				if(includeUnmatched || this.job.unmatchedSubjects.indexOfMatch(subject, 'id') != -1) {
					continue;
				}

				var ratio = 0.8;
				var crop = undefined;
				this.job.batches.forEach(function(batch) {
					if(!batch.subjects) {
						return;
					}
	
					var matchedSubject = batch.subjects.getMatch(subject, 'id');
					if(matchedSubject) {
						if(batch.aspectRatio) {
							ratio = batch.aspectRatio;
						}
						if(batch.cropSelection && !crop && matchedSubject.yearbookPhoto) {
							crop = matchedSubject.yearbookPhoto.photo_crops.find(function(crop) {
								return crop.name.toLowerCase() === batch.cropSelection;
							});
						}
					}
				});

				var issues = $.SubjectManagement.getSubjectIssues(subject, null, {
					ratio: ratio,
					subjects: this.job.subjects,
					crop: crop
				});
				if(issues.length) {
					subjectsWithIssues.push(subject);
				}
			}

			return subjectsWithIssues;
		},
		updateResortSubjectsButton: function() {
			var canResort = false;
			if(this.currentBatch && this.currentBatch.id !== -1 && !this.batch.isSearching() && this.editable) {
				var subjects = $.merge([], this.currentBatch.subjects);
				$.SubjectManagement.sortSubjects(subjects, this.job.sortBy);

				if(!$.arrayEquals(subjects.arrayOfProperties('id'), this.currentBatch.subjects.arrayOfProperties('id'))) {
					canResort = true;
				}
			}

			if(canResort) {
				if(!this.sortSubjectsButton) {
					this.sortSubjectsButton = $('<div class="ui yellow button"><i class="ui text height icon"></i> Resort subjects</div>').click(function() {
						div.resortCurrentBatch();
					}).insertBefore(this.searchBox);

					this.requireSchoolButtons = this.requireSchoolButtons.add(this.sortSubjectsButton);
				}
			} else if(this.sortSubjectsButton) {
				$(this.sortSubjectsButton).remove();
				this.sortSubjectsButton = null;

				this.requireSchoolButtons = this.requireSchoolButtons.not(this.sortSubjectsButton);
			}
		},
		resortCurrentBatch: function() {
			$.SubjectManagement.sortSubjects(this.currentBatch.subjects, this.job.sortBy);
			this.batch.loadSubjects(this.currentBatch);
			this.batch.saveSubjectOrder();

			this.updateResortSubjectsButton();
		},
		setupFieldValues: function(job) {
			if(job.fieldValues) {
				this.batch.setSubjectFieldValues(job.fieldValues);
				return;
			}

			var autoCompleteFields = $.merge([], $.SubjectManagement.AUTO_COMPLETE_FIELDS);
			job.fieldSet.editableFields.forEach(function(editableField) {
				if(!editableField.group) {
					return;
				}

				if(!autoCompleteFields.includes(editableField.name)) {
					autoCompleteFields.push(editableField.name);
				}
			});
			job.autoCompleteFields = autoCompleteFields;

			var fields = job.template.subject_fields;

			// Load possible field values
			var fieldValueGroups = {}, includesBatches = {};
			for(let i = 0; i < autoCompleteFields.length; i++) {
				// Get the id we are looking for
				var fieldName = autoCompleteFields[i];
				var fieldIds = [];
				for(let j = 0; j < fields.length; j++) {
					var field = fields[j];

					var fieldKey = field.field_key || field.label;
					if(fieldName == fieldKey) {
						fieldIds.push(field.id);
					}
				}

				var fieldValues = [];
				if(fieldIds.length) {
					// Loop through all subjects
					for(let j = 0; j < job.subjects.length; j++) {
						let subject = job.subjects[j];

						fieldIds.forEach(function(fieldId) {
							var value;
							// Switch for depending on if this is called before or after subjects are setup with proper structure
							if (subject.properties) {
								value = subject.properties[fieldId];
							} else {
								value = subject[fieldName];
							}
							if (fieldValues.indexOf(value) == -1 && $.isInit(value)) {
								fieldValues.push(value);
							}
						});
					}
				}

				if(fieldValues.length) {
					var missingBatches = [];
					for(let j = 0; j < this.batches.length; j++) {
						var name = this.batches[j].name;
						if(fieldValues.indexOf(name) == -1 && missingBatches.indexOf(name) == -1) {
							missingBatches.push(name);
						}
					}

					if(missingBatches.length / this.batches.length < 0.8) {
						for(let j = 0; j < missingBatches.length; j++) {
							fieldValues.push(missingBatches[j]);
						}
						includesBatches[fieldName] = true;
					}
				}

				fieldValueGroups[fieldName] = fieldValues;
			}

			for(var group in fieldValueGroups) {
				fieldValueGroups[group].batchNameSort(null);
			}

			job.fieldValues = fieldValueGroups;
			job.includesBatches = includesBatches;
			this.batch.setSubjectFieldValues(fieldValueGroups);

			job.fieldIdMap = $.SubjectManagement.createFieldMap(job.template);
			job.fieldLabelMap = $.SubjectManagement.createFieldLabelMap(job.fieldIdMap, job.subjects);
		},
		setupSubjectsWithData: function (job) {
			// Already setup
			if (job.subjects.length === 0 || !job.subjects[0].properties) {
				return;
			}

			job.placeholderSubjects = [];
			this.subjects = job.subjects = $.SubjectManagement.populateSubjectsWithTemplate(job.subjects, job.template, job.fieldIdMap, job.placeholderSubjects);
			
			if($.SubjectManagement.cacheSubjectFields) {
				$.SubjectManagement.cacheSubjectFields(job.jobId, job.subjects, job.placeholderSubjects, job.template, job);
			}
			this.subjects.forEach(subject => {
				populateFullName(subject);
			});
		},
		getJob: function () {
			return this.job;
		},
		setBatch: function(batch) {
			this.loadBatch(batch);

			if(this.cacheLastSelectedBatch && batch.id !== -1) {
				var lastLoadedBatches = $.userExtras.lastLoadedBatches || {};
				lastLoadedBatches[this.jobId] = batch.id;
				$.ajax({
					url: 'ajax/saveUserExtra.php',
					dataType: 'json',
					data: {
						name: 'lastLoadedBatches',
						value: lastLoadedBatches
					},
					type: 'POST'
				});
			}
		},
		loadBatch: function(batch) {
			this.batches.forEach(function(batch) {
				batch.active = false;
				batch.searching = false;
			});
			this.trashDropArea.removeClass('blue active').addClass('basic');
			this.stopSearch(true);

			if(batch) {
				batch.active = true;

				if(batch.id === -1) {
					this.trashDropArea.removeClass('basic').addClass('blue active');
					batch.subjects = this.job.unmatchedSubjects;
				}
			}

			if (this.loadingSchool) {
				this.onFinishLoadBatch = batch;
			} else {
				this.currentBatch = batch;
				this.updateResortSubjectsButton();
				try {
					this.batch.loadSubjects(batch);
				} catch (e) {
					console.error(e);
					this.batch.error();
				}
			}
		},
		setBatchVisible: function(activeBatch) {
			var me = this;
			this.batchList.$nextTick(function() {
				me.batchList.$nextTick(function() {
					if(!activeBatch) {
						activeBatch = div.batches.find(function(batch) {
							return batch.active;
						});
					}
					
					if(activeBatch) {
						var batchDiv = div.batchList.$el.querySelector('#batch' + activeBatch.id);
						if(batchDiv) {
							batchDiv.scrollIntoView({
								inline: 'center',
								block: 'nearest'
							});
						}
					}
				});
			});
		},
		updateBatchDivCount: function(batch) {
			if(batch.id === -1) {
				this.trashDropArea.find('.label').text(batch.subjects.length);
				this.updateTrashDropAreaPopup(batch);
			}
		},
		updateTrashDropAreaPopup: function(batch) {
			if($(this.trashDropArea).hasClass('hasPopup')) {
				$(this.trashDropArea).popup('destroy').removeClass('hasPopup');
			}

			if(batch.subjects.length && this.allowClearTrash) {
				this.trashDropArea.removeAttr('data-tooltip').addClass('hasPopup').popup({
					html: 'Drag a subject\'s portrait here to put them in the Trash<p/>' +
						'<div class="ui compact red button">Clear Trash</div>',
					hoverable: true,
					delay: {
						show: 0,
						hide: 200
					},
					distanceAway: -10,
					onCreate: function () {
						$(this).find('.red.button').click(function () {
							if(div.loadingSchool) {
								return;
							}
			
							var trashBatch = div.getTrashBatch();
							$.Confirm('Confirm Clear Trash', 'Are you sure that you want to delete ' + trashBatch.subjects.length + ' subject(s) that are in the Trash?', function () {
								div.clearTrash();
							});
						});
					}
				});
			} else {
				this.trashDropArea.attr('data-tooltip', 'Drag a subject\'s portrait here to put them in the Trash');
			}
		},
		addSubjectToBatch: function (newBatch, subjects) {
			if (!$.isArray(subjects)) {
				subjects = [subjects];
			}
			subjects = subjects.filter(function(subject) {
				return !!subject;
			});
			if(!subjects.length) {
				console.error('passed in bogus subjects: ', arguments);
				return;
			}

			var alreadyExists = [];
			subjects.forEach(function(subject, index) {
				if(!subject) {
					$.fireErrorReport(null, 'Missing subject card', 'Missing subject card during move', {
						subjects: subjects,
						index: index,
						subject: subject
					});
				}

				if (newBatch.subjects.indexOfMatch(subject, 'id') != -1) {
					alreadyExists.push(subject);
				}
			});
			if (alreadyExists.length) {
				names = alreadyExists.joinOnAnd('First Name', ', ', ' and ');
				$.Alert('Warning', names + ' already exists in ' + newBatch.name);
				return;
			}

			var names = subjects.joinOnAnd('First Name', ', ', ' and ');
			var content = $('<div class="content">');

			var mustMove = newBatch.id == -1;
			// Necessary for when moving from Trash while searching
			for (let i = 0; i < subjects.length; i++) {
				var subjectCard = $(this.batch).find('#subject' + subjects[i].id)[0];
				if (subjectCard && subjectCard.batch && subjectCard.batch.id == -1) {
					mustMove = true;
				}
			}
			var descriptionVerb = 'Move or Copy';
			if (mustMove) {
				descriptionVerb = 'Move';
			}

			// If we are moving to the trash and this is a copy, update verbage to make it clear we are removing a copy
			var descriptionText = 'Would you like to ' + descriptionVerb.toLowerCase() + ' ' + names + ' to ' + newBatch.name + '?';
			if(newBatch.id === -1) {
				var copyPrefix = '';
				this.batches.forEach(function(otherBatch) {
					subjects.forEach(function(subject) {
						var match = otherBatch.subjects.getMatch(subject, 'id');
						if(match && match !== subject) {
							copyPrefix = 'this copy of ';
						}
					});
				});

				descriptionText = 'Would you like to remove ' + copyPrefix + names + '?'; 
			}

			$('<div class="description">')
				.text(descriptionText)
				.appendTo(content);

			var negativeButton = 'Move';
			var positiveButton = 'Copy';
			if (mustMove) {
				negativeButton = 'Cancel';

				if(newBatch.id === -1) {
					positiveButton = 'Remove';
				} else {
					positiveButton = 'Move';
				}
			}

			var me = this;
			var clicked = false;
			$('<div class="ui modal"><i class="close icon"></i><div class="header">' + descriptionVerb + '?</div></div>')
				.append(content)
				.append('<div class="actions"><div class="ui negative button">' + negativeButton + '</div><div class="ui positive button">' + positiveButton + '</div></div>')
				.modal({
					onDeny: function () {
						if(clicked) {
							return;
						}

						if(!mustMove) {
							me.addSubjectToBatchCall(newBatch, subjects, false);
						}
						clicked = true;
					},
					onApprove: function () {
						if(clicked) {
							return;
						}

						if(!mustMove) {
							me.addSubjectToBatchCall(newBatch, subjects, true);
						} else {
							me.addSubjectToBatchCall(newBatch, subjects, false);
						}
						clicked = true;
					},
					onHidden: function () {
						$(this).remove();
					}
				}).modal('show');
		},
		addSubjectToBatchCall: function (newBatch, subjects, copy) {
			// Add it into the new batch
			var currentBatch, subjectCard;
			var events = [];
			let subject;
			for(let i = 0; i < subjects.length; i++) {
				subject = subjects[i];
				subjectCard = $(this.batch).find('#subject' + subject.id)[0];
				if(!subjectCard) {
					$.fireErrorReport('Failed to move subjects', 'Missing subject card', 'Missing subject card during move', {
						newBatch: newBatch,
						subjects: subjects,
						findSubject: subject,
						copy: copy
					});
					return;
				}
				currentBatch = subjectCard.batch;

				var addToNewBatch = true;
				if(newBatch.id == -1) {
					for(let j = 0; j < this.batches.length; j++) {
						let batch = this.batches[j];
						if (batch == currentBatch) {
							continue;
						}

						if (batch.subjects.indexOfMatch(subject, 'id') != -1) {
							addToNewBatch = false;
							break;
						}
					}
				}

				if(addToNewBatch) {
					var index = this.batch.getSubjectSortedPosition(newBatch.subjects, subject);
					newBatch.subjects.splice(index, 0, $.extend(true, {}, subject));

					if(newBatch.id !== -1) {
						events.push({
							action: 'insert',
							args: [$.extend(true, {}, subject), index - 1],
							context: ['batches', newBatch.id, 'subjects'],
							extras: {
								idProp: 'id'
							}
						});
					}
				}
			}

			var saveSubjects = $.SubjectManagement.getAppSpecificSubjectsData(newBatch.subjects);

			var batches = [];
			if(newBatch.id != -1) {
				batches.push({
					batchId: newBatch.id,
					subjects: saveSubjects
				});
			}

			// If move, remove existing subject
			if(!copy) {
				var currentSaveSubjects;
				if(!this.batch.searching) {
					for (let i = 0; i < subjects.length; i++) {
						$(this.batch).find('#subject' + subjects[i].id).remove();

						if(currentBatch.id != -1) {
							events.push({
								action: 'remove',
								args: [$.extend(true, {}, subject), this.batch.subjects.indexOfMatch(subject, 'id')],
								context: ['batches', currentBatch.id, 'subjects'],
								extras: {
									idProp: 'id'
								}
							});
						}
					}
					currentSaveSubjects = this.batch.updateBatchSubjects();
					this.batch.subjectIndex -= subjects.length;
				} else {
					for (let i = 0; i < subjects.length; i++) {
						if(currentBatch.id != -1) {
							events.push({
								action: 'remove',
								args: [$.extend(true, {}, subject), currentBatch.subjects.indexOfMatch(subject, 'id')],
								context: ['batches', currentBatch.id, 'subjects'],
								extras: {
									idProp: 'id'
								}
							});
						}

						currentBatch.subjects.removeMatch(subjects[i], 'id');
					}

					currentSaveSubjects = $.SubjectManagement.getAppSpecificSubjectsData(currentBatch.subjects);
				}

				if(currentBatch.id != -1) {
					batches.push({
						batchId: currentBatch.id,
						subjects: currentSaveSubjects
					});
				} else {
					// updateBatchSubjects changes the array reference so we need to update them to make sure they all stay referencing the same set
					this.job.unmatchedSubjects = currentBatch.subjects;
					var trashBatch = this.getTrashBatch();
					if(trashBatch && trashBatch !== currentBatch) {
						trashBatch.subjects = this.job.unmatchedSubjects;
					}
				}
			}

			var me = this;
			this.batch.startLoading(false);
			$.ajax({
				url: 'ajax/saveBatch.php',
				dataType: 'json',
				data: {
					batches: JSON.stringify(batches)
				},
				type: 'POST',
				success: function () {
					// Destination batch gets increased count
					me.updateBatchDivCount(newBatch);

					// If move, decrement active batch count
					if(!copy) {
						me.updateBatchDivCount(currentBatch);
						if(newBatch.id != -1) {
							subjects.forEach(function(subject) {
								var newBatchSubject = newBatch.subjects.getMatch(subject, 'id');
								me.updateSubjectAfterMove(currentBatch, newBatch, newBatchSubject);
							});
						} else if(me.batch.searching) {
							$(subjectCard).find('.subjectBatchRibbon').text(newBatch.name);
							subjectCard.batch = newBatch;
						}

						me.updateSubjectIssuesButton();
					}

					me.userEvents.addEvents(events);
					me.batch.stopLoading();
				},
				error: function () {
					me.batch.stopLoading();
					$.Alert('Error', 'Failed to insert subject into new batch');
				}
			});
		},
		updateSubjectAfterMove: function (currentBatch, newBatch, subject) {
			var subjectCard = $(this.batch).find('#subject' + subject.id)[0];
			if(!this.updateSubjectsFromBatchChanges) {
				if(this.batch.searching && subjectCard) {
					$(subjectCard).find('.subjectBatchRibbon').text(newBatch.name);
					subjectCard.batch = newBatch;
				}
				
				return;
			}
			// Only time this will matter is for moving while searching
			if(subjectCard) {
				currentBatch = subjectCard.batch;
			}
			var startProperties = {};
			var properties = {}, visibleProperties = {};
			var fieldMap = this.job.fieldLabelMap;

			for(let i = 0; i < this.job.autoCompleteFields.length; i++) {
				var field = this.job.autoCompleteFields[i];

				// Want to change field if either old values matches batch name or we are moving from Trash with an explicit groupBy
				if(subject[field] == currentBatch.name || (subject[field] && field === 'Teacher' && currentBatch.name.indexOf(subject[field] + ' - ') === 0 && subject[field].length > 1) || (this.job.groupBy == field && currentBatch.id === -1)) {
					var newValue = newBatch.name;
					if(field === 'Teacher' && newBatch.name.indexOf(' - ') !== -1 && this.job.fieldValues.Teacher) {
						var checkTeacher = newBatch.name.substr(0, newBatch.name.indexOf(' - '));
						if(this.job.fieldValues.Teacher.indexOf(checkTeacher) !== -1) {
							newValue = checkTeacher;
						}
					}
					startProperties[field] = subject[field];
					subject[field] = properties[fieldMap[field]] = visibleProperties[field] = newValue;

					if(field != 'Grade') {
						// Check what grade to move to
						var gradeCount = {}, highestGrade = null;
						for (let j = 0; j < newBatch.subjects.length; j++) {
							var grade = newBatch.subjects[j]['Grade'];
							if (grade) {
								if (gradeCount[grade]) {
									gradeCount[grade]++;
								} else {
									gradeCount[grade] = 1;
								}

								if (!highestGrade || gradeCount[highestGrade] < gradeCount[grade]) {
									highestGrade = grade;
								}
							}
						}

						if(highestGrade) {
							startProperties.Grade = subject.Grade;
							subject['Grade'] = properties[fieldMap['Grade']] = visibleProperties['Grade'] = highestGrade;
						}
					}

					break;
				}
			}

			if($.getObjectCount(properties)) {
				if(this.batch.searching && subjectCard) {
					subjectCard.startLoading();
				}

				$.plicAPI({
					method: 'projects/' + this.job.plicProjectId + '/subjects/' + subject.id,
					params: {
						subject: {
							properties: properties,
							merge_properties: true
						}
					},
					type: 'PUT',
					accept: 'application/vnd.plic.io.v1+json',
					success: function() {
						if(subjectCard) {
							for(var name in visibleProperties) {
								subjectCard.setVisibleProperty(name, visibleProperties[name]);
								subjectCard.subject[name] = visibleProperties[name];
							}
							subjectCard.stopLoading();
						}

						div.userEvents.addEvent({
							action: 'update',
							args: [startProperties, visibleProperties],
							context: ['subjects', subject.id]
						});
					},
					error: function() {
						$.Alert('Error', 'Failed to save changes');
						if(subjectCard) {
							subjectCard.stopLoading();
						}
					}
				});
			}

			if(this.batch.searching && subjectCard) {
				$(subjectCard).find('.subjectBatchRibbon').text(newBatch.name);
				subjectCard.batch = newBatch;
			}
		},
		editOnAll: function (subject, currentBatch) {
			if(!currentBatch) {
				currentBatch = this.currentBatch;
			}

			var batches = [];
			for (let i = 0; i < this.job.batches.length; i++) {
				let batch = this.job.batches[i];
				if(batch == currentBatch) {
					// If we are editing a copy in search we still need to update this
					var batchSubject = batch.subjects.getMatch(subject, 'id');
					if(batchSubject && subject != batchSubject) {
						$.SubjectManagement.PER_BATCH_PROPERTIES.forEach(function(prop) {
							batchSubject[prop] = subject[prop];
						});
					}

					continue;
				}

				if (subject['On All'] && subject['Teacher Priority']) {
					if (batch.subjects.indexOfMatch(subject, 'id') == -1) {
						var index = this.batch.getSubjectSortedPosition(batch.subjects, subject);
						batch.subjects.splice(index, 0, $.extend(true, {}, subject));
						batches.push($.SubjectManagement.getAppSpecificBatchData(batch));
					}
				} else {
					batch.subjects.removeMatch(subject, 'id');
					batches.push($.SubjectManagement.getAppSpecificBatchData(batch));
				}
			}

			if (batches.length) {
				this.batchSaveBatchOrders(batches);
			}
		},
		batchSaveBatchOrders: function (batches) {
			var me = this;
			$.ajax({
				url: 'ajax/saveBatch.php',
				dataType: 'json',
				data: {
					batches: JSON.stringify(batches)
				},
				type: 'POST',
				success: function () {
					for (let i = 0; i < batches.length; i++) {
						let batch = batches[i];
						me.updateBatchDivCount(batch);
					}
				},
				error: function () {
					$.Alert('Error', 'Failed to move subject into new batch');
				}
			});

			// NOTE: Do not add event here since handled directly in card.saveChanges
		},
		customBatchFilter: function() {
			$.SettingsBuilderDialog([
				{
					id: 'groupBy',
					description: 'Group By',
					type: 'dropdown',
					options: $.merge(['Project'], div.getCustomSortFields()),
					defaultValue: 'Teacher',
					multiple: true,
					customRule: 'empty'
				},
				{
					id: 'sortBy',
					description: 'Sort By',
					type: 'dropdown',
					options: div.getCustomSortFields(),
					defaultValue: 'Last Name',
					multiple: true,
					customRule: 'empty'
				},
				{
					id: 'sortDescending',
					description: 'Sort Descending',
					type: 'checkbox',
					defaultValue: false
				}
			], {
				title: 'Filter By',
				onSettingsApplied: function(settings) {
					if(settings.sortDescending) {
						var sortByFields = settings.sortBy.split(',');
						if(sortByFields.length) {
							settings.sortBy = sortByFields.join(' (DESC),') + ' (DESC)';
						}
					}

					div.confirmBatchFilter(settings.groupBy + ' and sort by ' + settings.sortBy);
				}
			});
		},
		getCustomSortFields: function() {
			var fields = [];
			for(let i = 0; i < this.job.template.subject_fields.length; i++) {
				var field = this.job.template.subject_fields[i];
				var label = field.field_key || field.label;

				// These fields really shouldn't be part of the PLIC data anyways
				if(['Teacher Priority', 'Individualize Staff', 'On All', 'photo_assignment', 'photo'].indexOf(label) != -1) {
					continue;
				}

				fields.push(label);
			}
			fields.sort();

			return fields;
		},
		confirmBatchFilter: function(filter) {
			var message = 'You are about to destroy all existing batches, and to recreate them ';

			var searchStr = ' and sort by ';
			var middleIndex = filter.indexOf(' and sort by ');
			if (filter.indexOf('Project') === 0) {
				message += 'as a whole project batch';
			} else if($.isArray(filter)) {
				message += 'with the default filter which guesses how to group based on the teacher/grade data';
			} else {
				var groupBy = filter;
				if(middleIndex != -1) {
					groupBy = filter.substring(0, middleIndex);
				}

				var groupByFields = groupBy.split(',');
				message += 'based off of the existing subject\'s "' + groupByFields.joinAnd(', ', ' and ') + '"';
			}

			if(middleIndex != -1) {
				var sortBy = filter.substring(middleIndex + searchStr.length);
				var sortByFields = sortBy.split(',');
				message += ' and to sort by their "' + sortByFields.joinAnd(', ', ' and ') + '"';
			}
			message += '. Do you want to continue?';

			$.ConfirmCheckbox('Confirm Recreating Batches', message, function () {
				div.recreateBatchFilter(filter, {
					filterByTeacherGrade: $.isArray(filter)
				});
			});
		},
		recreateBatchFilter: function (field, settings) {
			settings = $.extend({
				filterByTeacherGrade: true
			}, settings);

			var me = this;
			this.startLoading();

			var subjects = this.getSubjectsWithBatchProperties();
			$.SubjectManagement.recreateBatchesFromFilter({
				job: this.job,
				jobId: this.jobId,
				filter: field,
				subjects: subjects,
				filterByTeacherGrade: settings.filterByTeacherGrade,
				success: function () {
					var job = me.job;
					for (var name in me.batchList.cachedData) {
						if (name.indexOf(me.jobId + '') != -1) {
							me.batchList.cachedData[name] = null;
						}
					}
					me.batchList.currentType = null;
					job.unmatchedSubjects = null;
					// So we re-construct includesBatches
					job.fieldValues = null;
					job.batches = null;
					me.job = null;

					me.loadSchool(job);
				},
				error: function () {
					me.error();
				}
			});
		},
		getSubjectsWithBatchProperties: function() {
			var batches = this.job.batches;
			if(!batches) {
				return this.job.subjects;
			}

			var subjects = [];
			this.job.subjects.forEach(function(subject) {
				var copy = $.extend(true, {}, subject);

				batches.forEach(function(batch) {
					var match = batch.subjects.getMatch(subject, 'id');
					if(match != null) {
						$.SubjectManagement.PER_BATCH_PROPERTIES.forEach(function(prop) {
							if($.isInit(match[prop])) {
								if($.isInit(copy[prop])) {
									if(match[prop] > copy[prop]) {
										copy[prop] = match[prop];
									}
								} else {
									copy[prop] = match[prop];
								}
							}
						});
					}
				});

				subjects.push(copy);
			});

			return subjects;
		},
		insertBatch: function (name) {
			for(let i = 0; i < this.batches.length; i++) {
				var otherBatch = this.batches[i];
				if(otherBatch.name === name) {
					$.Alert('Error', 'Batch name "' + name + '" is already taken.  Please try a different one.');
					return;
				}
			}

			// Start loading
			var me = this;
			this.startLoading();
			$.ajax({
				url: 'ajax/createBatch.php',
				dataType: 'json',
				data: {
					jobId: this.jobId,
					name: name
				},
				type: 'POST',
				success: function (data) {
					let batch = data;
					me.job.batches.forEach(function(jobBatch) {
						jobBatch.subjects.forEach(function(subject) {
							if(subject['Teacher Priority'] && subject['On All'] && batch.subjects.indexOfMatch(subject, 'id') == -1) {
								batch.subjects.push($.extend(true, {}, subject));
							}
						});
					});
					$.SubjectManagement.sortSubjects(batch.subjects);

					// Don't have to edit existig subject cards since they are getting destroyed right away anyways!
					for (var group in me.job.includesBatches) {
						var fieldValues = me.job.fieldValues[group];
						fieldValues.push(name);
						fieldValues.batchNameSort(null);
					}

					me.insertBatchDiv(batch);
					me.setBatchVisible(batch);
					me.userEvents.addEvent({
						action: 'insert',
						args: [batch, me.batches.indexOf(batch)],
						context: ['batches']
					});

					me.loadBatch(batch);
					me.batch.saveSubjectOrder();
					me.stopLoading();
				},
				error: function (jqXHR) {
					me.error();

					if(jqXHR.responseJSON && jqXHR.responseJSON.reason && jqXHR.responseJSON.reason.includes('Batch name is already taken')) {
						$.Alert('Error', 'Batch name "' + name + '" is already taken.  Please try a different one.');
					} else {
						$.Alert('Error', 'Failed to create batch');
					}
				}
			});
		},
		insertBatchDiv: function(batch) {
			var insertBatch = false;
			if(this.batches.indexOfMatch(batch, 'id') === -1) {
				insertBatch = true;
			}
			if(batch.active === undefined) {
				batch.active = false;
				batch.searching = false;
			}

			// Create new batch and insert at that location
			if(insertBatch) {
				this.batches.push(batch);
			}
			this.batches.batchNameSort();
		},
		stopSearch: function (blockLoad) {
			if(!this.batch.isSearching()) {
				return;
			}

			var input = this.searchBox.find('input');

			input.val('');
			input.data('lastQuery', null);
			if (input.data('ajax')) {
				input.data('ajax').abort();
				input.data('ajax', null);
			}

			this.searchBox.find('.search').show();
			this.searchBox.find('.close').hide();

			// Check if a previous search is being displayed
			if (this.searchBox.hasClass('loading') || $(this.batchList.$el).find('.teal').length) {
				this.searchBox.removeClass('loading');
			}
			this.batch.stopSearching();
			if (!blockLoad) {
				this.loadBatch(this.currentBatch);
			}
			this.searchBox.removeClass('loading');
			this.updateSubjectIssuesButton();
			this.updateResortSubjectsButton();
		},
		updateBatchName: function(batch) {
			if(this.loadingSchool) {
				return;
			}

			var me = this;

			$.EditNameModal('Rename Batch', 'Name', batch.name, function(newName) {
				let teacherTemplateId;
				if(me.updateSubjectsFromBatchChanges) {
					teacherTemplateId = me.job.fieldLabelMap['Teacher'];
				}
				var oldName = batch.name;

				for(let i = 0; i < me.batches.length; i++) {
					var otherBatch = me.batches[i];
					if(otherBatch == batch) {
						continue;
					}

					if(otherBatch.name === newName) {
						$.Alert('Error', 'Batch name "' + newName + '" is already taken.  Please try a different one.');
						return;
					}
				}

				me.batchList.startLoading();
				$.ajax({
					url: 'ajax/renameBatch.php',
					dataType: 'json',
					data: {
						jobId: me.jobId,
						batchId: batch.id,
						batchName: newName,
						teacherTemplateId
					},
					type: 'POST',
					success: function (data) {
						me.batchList.stopLoading();

						batch.name = newName;
						me.batches.batchNameSort();
						me.setBatchVisible(batch);
						me.onBatchNameUpdated(batch.id, oldName, newName);
						me.userEvents.addEvent({
							action: 'update',
							args: [oldName, newName],
							context: ['batches', batch.id, 'name']
						});
					},
					error: function (jqXHR) {
						me.batchList.stopLoading();

						if(jqXHR.responseJSON && jqXHR.responseJSON.reason && jqXHR.responseJSON.reason.includes('Batch name is already taken')) {
							$.Alert('Error', 'Batch name "' + newName + '" is already taken.  Please try a different one.');
						} else {
							$.Alert('Error', 'Failed to rename batch');
						}
					}
				});
			});
		},
		onBatchNameUpdated: function(batchId, oldName, newName) {
			if(!this.updateSubjectsFromBatchChanges) {
				return;
			}

			if(this.currentBatch.id == batchId) {
				$(this.batch).find('.subjectCard').each(function() {
					if(this.subject['Teacher'] == oldName) {
						this.subject['Teacher'] = newName;
						let jobSubject = div.job.subjects.getMatch(this.subject, 'id');
						if(jobSubject) {
							jobSubject['Teacher'] = newName;
						}

						let property = $(this).find('.subjectProperty[name="Teacher"]');
						$(property).data('init', newName);
						$(property).find('.subjectLabelValue').text(newName);
						$(property).find('.ui.input .text').text(newName);
						$(property).find('.ui.input .item:contains("' + oldName + '")').text(newName);
					}
				});
			}

			for(var group in this.job.includesBatches) {
				var fieldValues = this.job.fieldValues[group];

				for(let i = 0; i < fieldValues.length; i++) {
					if(fieldValues[i] == oldName) {
						fieldValues[i] = newName;
						break;
					}
				}
			}
		},
		confirmRemoveBatch: function(batch) {
			var me = this;
			$.Confirm('Confirm Deletion', 'Are you sure that you want to delete the batch ' + batch.name + '?', function() {
				me.removeBatch(batch);
			});
		},
		removeBatch: function(batch) {
			this.batchList.startLoading();
			var me = this;
			$.ajax({
				url: 'ajax/deleteBatch.php',
				dataType: 'json',
				data: {
					jobId: this.jobId,
					batchId: batch.id
				},
				type: 'POST',
				success: function (data) {
					me.userEvents.addEvent({
						action: 'remove',
						args: [batch, me.batches.indexOf(batch)],
						context: ['batches'],
						extras: {
							idProp: 'id'
						}
					});

					me.removeBatchDiv(batch);
					me.batchList.stopLoading();
				},
				error: function () {
					me.batchList.stopLoading();
					$.Alert('Error', 'Failed to delete batch');
				}
			});
		},
		removeBatchDiv: function(batch) {
			var batchIndex = this.batches.indexOfMatch(batch, 'id');
			if(batchIndex !== -1) {
				this.batches.splice(batchIndex, 1);
			}
			this.moveSubjectsToUnmanaged(batch);

			if(batch.id == this.currentBatch.id) {
				if(this.batches.length) {
					this.loadBatch(this.batches[0]);
				} else {
					this.loadBatch(this.getTrashBatch());
				}
				this.setBatchVisible();
			}
		},
		moveSubjectsToUnmanaged: function (batch) {
			// Add any subjects not in any other batch to Unmatched batch
			var strandedSubjects = $.merge([], batch.subjects);
			for (let i = 0; i < strandedSubjects.length; i++) {
				let subject = strandedSubjects[i];
				for (let j = 0; j < this.batches.length; j++) {
					if (this.batches[j].subjects.indexOfMatch(subject, 'id') != -1) {
						strandedSubjects.splice(i, 1);
						i--;
						break;
					}
				}
			}

			// If not found anywhere else, add to unmanaged subjects
			for (let i = 0; i < strandedSubjects.length; i++) {
				let subject = strandedSubjects[i];

				if (this.job.unmatchedSubjects.indexOfMatch(subject, 'id') == -1) {
					var index = this.batch.getSubjectSortedPosition(this.job.unmatchedSubjects, subject);
					this.job.unmatchedSubjects.splice(index, 0, subject);
				}
			}

			this.updateBatchDivCount(this.getTrashBatch());
		},
		filterForSearch: function(search) {
			var subjects = $.merge([], this.job.subjects, this.job.unmatchedSubjects);
			$.merge(subjects, this.job.placeholderSubjects);
			if(!subjects || !subjects.length) {
				return;
			}
			search = search.toLowerCase();

			var results = [];
			var searchFields = [['First Name', 'Last Name'], 'Student ID'];
			for(let i = 0; i < subjects.length; i++) {
				let subject = subjects[i];
				for(let j = 0; j < searchFields.length; j++) {
					var searchField = searchFields[j];
					var searchVal = '';
					if($.isArray(searchField)) {
						for(var k = 0; k < searchField.length; k++) {
							var searchSubField = searchField[k];
							if(subject[searchSubField]) {
								searchVal += subject[searchSubField].toLowerCase();
								if(k < searchField.length - 1) {
									searchVal += ' ';
								}
							}
						}
					} else if(subject[searchField]) {
						searchVal = subject[searchField].toLowerCase();
					}

					if(searchVal && searchVal.indexOf(search) != -1) {
						results.push(subject);
					}
				}
			}

			this.filterForSubjects(results);
		},
		filterForSubjects: function(results) {
			this.batch.startSearching();

			var batchResults = {}, batchSubjects = {};
			var batches = $.merge([{
				id: -1,
				name: 'Trash',
				subjects: this.job.unmatchedSubjects
			}], this.job.batches);
			for(let i = 0; i < batches.length; i++) {
				let batch = batches[i];

				for(let j = 0; j < results.length; j++) {
					let subject = results[j];

					var batchSubject;
					if($.isArray(batch.subjects)) {
						batchSubject = batch.subjects.getMatch(subject, 'id');
					}
					if (batchSubject) {
						if(!batchResults[batch.name]) {
							batchResults[batch.name] = batch;
							batchSubjects[batch.name] = [];
						}

						batchSubjects[batch.name].push(batchSubject);
					}
				}
			}

			this.batches.forEach(function(batch) {
				batch.active = false;
				batch.searching = false;
			})
			this.batch.setSortable(false);
			this.batch.clear();

			// Add subjects to listings
			for(let i in batchResults) {
				let batch = batchResults[i];
				var batchList = batchSubjects[i];

				// Ignore invalid batches
				if(!batch.subjects) {
					continue;
				}

				// Highlight all batches that this is a result of
				batch.searching = true;

				var cardList = this.batch.cardList;
				for(let j = 0; j < batchList.length; j++) {
					cardList.append(new $.SubjectManagementCard(batchList[j], {
						job: this.job,
						batch: batch,
						batchLabel: true,
						individualizedOption: this.individualizedOption,
						editable: this.editable,
						list: this.batch
					}));
				}
			}
		},
		generateSubjectDirectory: function() {
			var button = this.subjectDirectoryButton;
			if(button.hasClass('loading')) {
				return;
			}

			button.addClass('loading');
			$.ajax({
				url: 'ajax/content-books.php',
				data: {
					type: 'subject-directory',
					jobId: this.job.jobId
				},
				dataType: 'json',
				success: function(data) {
					if(data.rendered) {
						$(button).removeClass('loading');
						div.onSubjectDirectoryRendered(data);
					} else {
						$.waitForWorker(data.resqueToken, {
							onComplete: function () {
								$(button).removeClass('loading');

								div.onSubjectDirectoryRendered(data);
							},
							onError: function () {
								$(button).removeClass('loading');
								$.Alert('Error', 'Failed to generate Subject Directory');
							}
						});

						$.Alert('Generating Subject Directory', 'We have started generating your Subject Directory.  This could take a while so we will send you an email when it is complete if you do not wish to wait.');
					}
				},
				error: function() {
					$(button).removeClass('loading');
					$.Alert('Error', 'Failed to generate Subject Directory');
				}
			});
		},
		onSubjectDirectoryRendered: function(data) {
			$.Alert('Subject Directory', 'Your Subject Directory is complete and available for <a target="_blank" href="' + data.downloadUrl + '">download</a>', null, {
				allowHtml: true
			});
		},
		addToFieldValues: function(field, value) {
			var fieldValues = this.job.fieldValues[field];
			if(value && fieldValues.indexOf(value) == -1) {
				fieldValues.push(value);
				fieldValues.batchNameSort(null);

				$(this.batch).find('.subjectCard').each(function() {
					this.recreateAutoCompleteOptions(field, fieldValues);
				});
			}
		},
		getTrashBatch: function() {
			return {
				id: -1,
				name: 'Trash',
				subjects: this.job.unmatchedSubjects
			};
		},
		clearTrash: function() {
			var trashBatch = this.getTrashBatch();

			let job = div.job;
			this.startLoading(false);
			var chain = new $.ExecutionChain(function() {
				// If we have switched jobs then something else already took over stopping loading
				if(div.job !== job) {
					return;
				}
				div.stopLoading();

				div.updateBatchDivCount(div.getTrashBatch());
				
				if(!div.currentBatch || div.currentBatch.id === -1) {
					div.loadBatch(div.getTrashBatch());
				}
			});
			trashBatch.subjects.forEach(function(subject) {
				chain.add($.getPlicAPI({
					method: 'subjects/' + subject.id,
					type: 'DELETE',
					success: function() {
						job.unmatchedSubjects.removeMatch(subject, 'id');
					}
				}));
			});

			chain.done();
		},
		getClasses: function() {
			if(this.job) {
				return this.job.batches;
			} else {
				return null;
			}
		},
		getSubjectTemplate: function() {
			return this.job.template;
		},
		getTemplateIdMap: function() {
			return this.job.fieldLabelMap;
		},

		bulkTitleCaseSubjects: function() {
			if(!this.job || this.isLoading()) {
				return;
			}

			this.loader.addClass('active');
			let chain = new $.ExecutionChain(() => {
				this.loader.removeClass('active');
				this.refreshSchool(true);

				this.loader.find('.ui.loader').text('Loading...');
			});

			let labelMap = this.job.fieldLabelMap;
			this.subjects.forEach(subject => {
				let plicProperties = {};

				let firstName = subject['First Name'] || '';
				let lastName = subject['Last Name'] || '';

				let caseFirstName = $.SubjectManagement.toNameCase(firstName);
				let caseLastName = $.SubjectManagement.toNameCase(lastName);
				
				if(firstName != caseFirstName) {
					plicProperties[labelMap['First Name']] = caseFirstName;
				}
				if(lastName != caseLastName) {
					plicProperties[labelMap['Last Name']] = caseLastName;
				}
				
				if(Object.keys(plicProperties).length) {
					chain.add($.getPlicAPI({
						method: 'subjects/' + subject.id,
						params: {
							subject: {
								properties: plicProperties,
								merge_properties: true
							}
						},
						type: 'PUT',
						success: () => {
							this.loader.find('.ui.loader').text('Updated ' + (chain.completeCount + 1) + ' of ' + chain.total + ' subjects...');
						},
						error: () => {
							this.loader.find('.ui.loader').text('Updated ' + (chain.completeCount + 1) + ' of ' + chain.total + ' subjects...');
						}
					}));
				}
			});
			this.loader.find('.ui.loader').addClass('text').text('Updated 0 of ' + chain.total + ' subjects...');
			chain.done();
		},

		onConsumeEvent: function(event) {
			if(event.context[0] === 'batches') {
				if(event.context.length === 1) {
					if(event.action === 'insert') {
						this.insertBatchDiv(event.args[0]);
					} else if(event.action === 'remove') {
						this.removeBatchDiv(event.args[0]);
					}
				} else {
					if(this.currentBatch && this.currentBatch.id == event.context[1]) {
						if(event.context.length >= 3 && event.context[2] === 'subjects') {
							this.batch.onConsumeBatchEvent(event);
						}
					}
					
					if(event.context.length === 3 && event.context[2] === 'name') {
						this.onBatchNameUpdated(event.context[1], event.args[0], event.args[1]);
					}
					
					if(event.context.length >= 3 && event.context[2] === 'subjects' && (event.action === 'insert' || event.action === 'remove')) {
						let batch = this.batches.getMatch({id: event.context[1]}, 'id');
						if(batch) {
							this.updateBatchDivCount(batch);
						}
					}
				}
			} else if(event.context[0] === 'subjects') {
				var card = $(this.batch).find('#subject' + event.context[1])[0];
				if(card) {
					card.onConsumeSubjectEvent(event);
				}
			}
		},
		onIgnoreEvent: function(event) {
			if(event.context[0] == 'pageSet' && event.context[1] == 'status') {
				this.job.status = event.args[1];
				this.refreshSchool();
			}
		},

		startLoading: function (dontEmpty) {
			this.batchList.startLoading(dontEmpty);
			this.batch.startLoading(dontEmpty);
		},
		stopLoading: function () {
			this.batchList.stopLoading();
			this.batch.stopLoading();
		},
		isLoading: function() {
			return this.batch.isLoading() || this.batchList.isLoading();
		},
		error: function () {
			this.stopLoading();
			this.batch.error();
		},
		destroy: function() {
			this.batch.destroy();
			this.dbQueue.closeWebSocket();
		}
	});

	div.schoolDropdown = $('<div class="ui floating dropdown labeled search icon button subjectSchoolDropdown"><i class="university icon"></i><span class="text">Select School</span><div class="menu"></div></div>');
	div.requireSchoolButtons = $();
	div.requireEditableSchoolButtons = $();

	if(!options || !options.filterByHidden) {
		div.filterDropdown = $('<div class="ui blue searchable floating dropdown labeled icon button"><i class="filter icon"></i><span class="text">Filter By</span></div>');
		var filterDropdownMenu = $('<div class="menu transition hidden" tabindex="-1"><div class="header">Group By</div></div>');

		var filterByOptions = [
			{
				name: 'Default (guess based on teacher/grade data)',
				color: 'green'
			},
			{
				name: 'Teacher',
				color: 'red'
			},
			{
				name: 'Grade',
				color: 'blue'
			},
			{
				name: 'Grade and sort by Teacher',
				color: 'yellow'
			},
			{
				name: 'Teacher,Grade',
				display: 'Teacher and Grade',
				color: 'purple'
			}
		];
		if(options && options.extraFilterByOptions) {
			$.merge(filterByOptions, options.extraFilterByOptions);
		}

		filterByOptions.push({
			name: 'Custom filter',
			color: 'teal'
		});

		for(let i = 0; i < filterByOptions.length; i++) {
			var option = filterByOptions[i];
			var optionName;
			if(option.name) {
				optionName = option.name;
			} else {
				optionName = option;
			}
			var optionDisplay = optionName;
			if(option.display) {
				optionDisplay = option.display;
			}

			var optionItem = $('<div class="item">')
				.attr('data-value', optionName)
				.text(optionDisplay);

			if(option.color) {
				$('<div class="ui empty circular label">').addClass(option.color).prependTo(optionItem);
			}

			filterDropdownMenu.append(optionItem);
		}

		div.filterDropdown.append(filterDropdownMenu);

		div.filterDropdown.dropdown({
			action: function (text, value) {
				$(div.filterDropdown).dropdown('hide');

				if(value.toLowerCase() == 'custom filter') {
					div.customBatchFilter();
				} else if(value.indexOf('Default ') === 0) {
					div.confirmBatchFilter($.SubjectManagement.getDefaultFilter(div.job));
				} else {
					div.confirmBatchFilter(value);
				}
			}
		});
		$(div).append(div.filterDropdown);
		div.requireSchoolButtons = div.requireSchoolButtons.add(div.filterDropdown);
	}

	div.createButton = $('<div class="ui icon positive button" data-content="Create new batch"><i class="plus icon"></i></div>').popup();
	div.createButton.on('click', function() {
		$.EditNameModal('Create Batch', 'Name', 'Batch Name', function(name) {
			div.insertBatch(name);
		});
	});
	$(div).append(div.createButton);
	div.requireSchoolButtons = div.requireSchoolButtons.add(div.createButton);

	if(options && options.subjectDirectory) {
		div.subjectDirectoryButton = $('<div class="ui icon blue button"><i class="print icon"></i></div>');
		div.subjectDirectoryButton.on('click', function() {
			div.generateSubjectDirectory();
		}).popup({
			content: 'Download Subject Directory'
		});
		$(div).append(div.subjectDirectoryButton);
		div.requireSchoolButtons = div.requireSchoolButtons.add(div.subjectDirectoryButton);
	}

	if(options.uploadPSPA) {
		div.uploadPSPAButton = $('<div class="ui yellow button"><i class="upload icon" /> Upload PSPA CD</div>');
		div.uploadPSPAButton.on('click', function() {
			$.activeProjectRow = div.job;
			$.managementTabs.tab('change tab', 'SubjectUploader.html');
		});
		$(div).append(div.uploadPSPAButton);
		div.requireEditableSchoolButtons = div.requireEditableSchoolButtons.add(div.uploadPSPAButton);
	}

	if(options.parentSubjectUploadTab) {
		var el = $('<div>').appendTo(div)[0];
		div.parentSubjectUploadButton = new Vue({
			el: el,
			data: {
				job: null,
				visible: true
			},
			template: '<div id="parentSubjectUploadButton" class="ui button" :class="{ orange: count, blue: !count }" @click="click" v-if="visible || count > 0">\
				<i v-if="!count" class="recycle icon" />\
				<i v-else class="checkmark icon" />\
				<span v-if="!count">Parent Portrait Uploads</span>\
				<span v-else>{{ count }} awaiting approval</span>\
			</div>',
			computed: {
				count: function() {
					if(!this.job || !this.job.guestSubjectPhotos) {
						return 0;
					} else {
						return this.job.guestSubjectPhotos.length;
					}
				}
			},
			methods: {
				click: function() {
					$.activeProjectRow = div.job;
					$.managementTabs.tab('change tab', 'ParentSubjectUploads.html');
				}
			},
			updated() {
				if(div.requireEditableSchoolButtons.filter('.disabled').length || div.requireSchoolButtons.filter('.disabled').length) {
					$(this.$el).addClass('disabled');
				}
			}
		});

		div.requireEditableSchoolButtons = div.requireEditableSchoolButtons.add(div.parentSubjectUploadButton.$el);
	}

	let advancedTools = [];
	if(options.changeLogTab) {
		advancedTools.push({
			label: 'Open subject changes since creation',
			onClick: function() {
				$.managementTabs.tab('change tab', 'SubjectChanges.html');
			}
		});
	}

	if(options.downloadExportTab) {
		advancedTools.push({
			label: 'Download export of subjects and images (Photolynx admin only)',
			onClick: function() {
				var form = $('<form method="POST">')
					.attr('action', $.AltDownloadDomain + 'ajax/streamExport.php')
					.attr('target', '_blank');
				$('<input type="hidden">')
					.attr('name', 'projectId')
					.attr('value', div.job.plicProjectId)
					.appendTo(form);

				$('<input type="hidden">')
					.attr('name', 'authToken')
					.attr('value', $.PlicToken)
					.appendTo(form);

				var subjects = $.merge([], div.job.subjects);
				$.merge(subjects, div.job.placeholderSubjects);
				var photosInZip = subjects.reduce(function(photos, subject) {
					(subject.photos ?? []).forEach(function(photo) {
						photos.push({
							name: 'photos/' + photo.upload_file_name,
							path: 'photos/' + photo.id + '/original/' + photo.upload_file_name
						});
					});

					return photos;
				}, []);

				var encodedJSON;
				if(window.btoa && window.pako) {
					// This is only necessary for users with crap upload speeds
					encodedJSON = window.btoa(window.pako.deflate(JSON.stringify(photosInZip), {to: 'string'}));
				} else {
					encodedJSON = JSON.stringify(photosInZip);
				}

				$('<input type="hidden">')
					.attr('name', 'photos')
					.attr('value', encodedJSON)
					.appendTo(form);

				var csvHeader = div.job.template.subject_fields.map(function(field) {
					return field.field_key || field.label;
				});
				csvHeader.push('Image Name');
				var cropCSVHeader = ['ImageName', 'ImagePath', 'CropL', 'CropT', 'CropW', 'CropH', 'CropErrors', 'Rotation'];
				var cropCSVData = [cropCSVHeader];

				var csvData = [csvHeader];
				subjects.forEach(function(subject) {
					var subjectData = div.job.template.subject_fields.map(function(field) {
						return subject[field.field_key || field.label] || '';
					});
					if(subject.yearbookPhoto) {
						subject.photos.forEach(function(photo) {
							var copySubjectData = $.merge([], subjectData);
							copySubjectData.push(photo.upload_file_name);
							csvData.push(copySubjectData);

							if(photo.yearbookCrop) {
								cropCSVData.push([
									photo.upload_file_name,
									photo.upload_file_name,
									photo.yearbookCrop.x,
									photo.yearbookCrop.y,
									photo.yearbookCrop.width,
									photo.yearbookCrop.height,
									'',
									0
								]);
							}
						});
					} else {
						subjectData.push('');
						csvData.push(subjectData);
					}
				});
				$('<input type="hidden">')
					.attr('name', 'subjectsCSV')
					.attr('value', $.formatCSV(csvData))
					.appendTo(form);
				$('<input type="hidden">')
					.attr('name', 'cropCSV')
					.attr('value', $.formatCSV(cropCSVData))
					.appendTo(form);
				
				$("body").append(form);
				form.submit();
				form.remove();
			}
		});
	}

	if(options.bulkCapitalize) {
		advancedTools.push({
			label: 'Change subjects to name case',
			onClick: function() {
				$.ConfirmCheckbox('Confirm changing name case', 'Are you sure you want to change the capitalization of every subject?  Every single subjects first and last name will be changed to capitalize the first letter and leave everything else lower case. ', function() {
					div.bulkTitleCaseSubjects();
				});
			}
		});
	}
	if(advancedTools.length) {
		div.advancedToolsButton = $('<div id="advancedToolsButton" class="ui dropdown icon button" data-tooltip="More tools">\
			<i class="ellipsis vertical icon" />\
		</div>').appendTo(div);

		let menu = $('<div class="menu">').appendTo(div.advancedToolsButton);
		advancedTools.forEach(tool => {
			let item = $('<div class="item">');

			item.text(tool.label);
			item.click(tool.onClick);
			menu.append(item);
		});
		$(div.advancedToolsButton).dropdown({
			action: 'hide'
		});

		div.requireEditableSchoolButtons = div.requireEditableSchoolButtons.add(div.advancedToolsButton);
	}

	div.searchBox = $('<div class="ui icon input" style="float: right; text-align: right;"><input type="search" class="" placeholder="Search..." aria-controls="organizationTable" style="width: auto;"><i class="search icon"></i><i class="close icon" style="display: none"></i></div>');
	div.searchBoxInput = div.searchBox.find('input');
	div.searchBoxInput.on('keyup', function(e) {
		// On escape, cancel current search
		var value = this.value;
		if (e.keyCode == 27 || !value) {
			div.stopSearch();
			return;
		}

		// Don't bother if the same as last query or if length is too small
		if($(this).data('lastQuery') == value || value.length <= 2) {
			return;
		}
		$(this).data('lastQuery', value);

		div.searchBox.find('.search').hide();
		div.searchBox.find('.close').show();

		div.filterForSearch(value);
	});
	div.searchBox.find('.close').css('cursor', 'pointer').click(function() {
		div.stopSearch();
	});
	$(div).append(div.searchBox);

	div.trashDropArea = $('<div class="ui basic button trashDropArea">')
		.append('<i class="trash icon">')
		.append('Trash ')
		.append('<a class="ui blue circular label">0</a>')
		.droppable({
			accept: '.subjectAvatar',
			drop: function (event, ui) {
				event.originalEvent.isHandledAlready = true;
				let batch = div.getTrashBatch();

				var card = ui.draggable.closest('.subjectCard');
				if (card.hasClass('blue')) {
					var cards = $(div).find('.subjectCard.blue');

					var subjects = [];
					$.each(cards, function () {
						subjects.push(this.subject);
					});
					div.addSubjectToBatch(batch, subjects);
				} else {
					let subject = card.data('subject');
					div.addSubjectToBatch(batch, subject);
				}
			}
		}).click(function() {
			div.loadBatch(div.getTrashBatch());
		});
	div.requireSchoolButtons = div.requireSchoolButtons.add(div.trashDropArea);

	div.lockedMessageText = "Your book has been submitted to be printed. You are not allowed to modify the book or it's data.";
	div.lockedMessage = $('<div class="ui warning message">').hide().appendTo(div);
	div.emptyMessage = $('<div class="ui warning message">There are no Subjects or Photos to display</div>').hide().appendTo(div);
	div.noProjectsMessage = $('<div class="ui warning message">There are no Yearbook Projects to view. Please contact your Studio or Photographer</div>').hide().appendTo(div);

	div.batchListEl = document.createElement('div');
	div.batchList = new Vue({
		template: '<subject-management-batch-header :batches="batches" :subject-management="subjectManagement" :loading="loading" :editable="editable" @updated="updated" />',
		data: function() {
			return {
				batches: [],
				subjectManagement: div,
				loading: false,
				editable: true
			};
		},
		methods: {
			clear: function() {
				this.batches = [];
			},
			startLoading: function() {
				this.loading = true;
			},
			stopLoading: function() {
				this.loading = false;
			},
			isLoading: function() {
				return this.loading;
			},
			updated: function() {
				this.$nextTick(function() {
					div.initializeSticky();
				});
			}
		},
		vuetify: window.Vuetify
	}).$mount();
	div.stickyBatchHeader = $('<div class="ui top sticky subjectManagementBatchHeaders">');
	div.stickyBatchHeader.append(div.batchList.$el).appendTo(div);
	$(div.stickyBatchHeader).append(div.trashDropArea);

	if(options && options.individualizedOption) {
		div.individualizedOption = true;
	}

	div.batch = new $.SubjectManagementBatch($.extend({
		parent: div
	}, options));
	$(div).append(div.batch);

	div.scrollToTop = $('<div class="centerWrapper"><div class="ui blue button scrollToTop">Scroll To Top</div></div>');
	div.scrollToTop.find('.button').click(function() {
		$('html, body').animate({
			scrollTop: 0
		}, 'fast');
	});
	$(div).append(div.scrollToTop);

	if(options && options.loader) {
		div.loader = options.loader;
	} else {
		div.loader = $('<div class="ui inverted dimmer"><div class="ui loader"></div></div>');
		$(div).append(div.loader);
	}

	if(options) {
		if(options.onLoadSchools) {
			div.onLoadSchools = options.onLoadSchools;
		}
		if(options.onLoadSchool) {
			div.onLoadSchool = options.onLoadSchool;
		}
		if(options.onCheckSchoolEditable) {
			div.onCheckSchoolEditable = options.onCheckSchoolEditable;
		}
		if(options.onBeforeLoadSchool) {
			div.onBeforeLoadSchool = options.onBeforeLoadSchool;
		}

		div.viewFullResolution = options.viewFullResolution;
		div.downloadFullResolution = options.downloadFullResolution;
		div.replacePhotoAssignment = options.replacePhotoAssignment;
		div.allowClearTrash = options.allowClearTrash;
		div.startFromScratch = options.startFromScratch;
		div.cacheLastSelectedBatch = options.cacheLastSelectedBatch;
		div.updateSubjectsFromBatchChanges = options.updateSubjectsFromBatchChanges;
	}
	div.requireSchoolButtons.addClass('disabled');
	div.requireEditableSchoolButtons.addClass('disabled');
	div.searchBoxInput.attr('disabled', true);

	div.dbQueue = new $.DBQueue({
		saveUrl: 'ajax/saveEvents.php',
		getSocket: function() {
			var url = 'wss?composer=' + div.job.jobId + '&token=' + $.PlicToken;
			if($.LoggedInAsUser) {
				url += '&originalToken=' + $.OriginalUserToken;
			}

			return url;
		},
		compressSaves: true,

		// TODO: Get rid of when we plug in real labels
		usersLoggedInLabels: $(),
		pageSet: div
	});
	div.userEvents = new $.UserEvents({
		pageSet: div,
		dbQueue: div.dbQueue,
		consumeEventContext: ['subjects', 'batches'],
		handleGlobalUndo: $.subjectManagementWebSocket
	});
	div.dbQueue.updateKeepAliveData($.extend(true, {
		currentPage: 'Subject Management'
	}, $.CurrentUser));
	div.userEvents.addOnConsumeEventHandler(div.onConsumeEventHandler = function(event) {
		div.onConsumeEvent(event);
	});
	div.userEvents.addOnIgnoreEventHandler(function(event) {
		div.onIgnoreEvent(event);
	});
	$.UserActivityModule(div.dbQueue);

	return div;
};